export const userMessage = {
  signUpSuccess: 'Sign up successfully',
  deleteSuccess: 'User deleted successfully',
  cannotDeleteSelf: 'You cannot delete yourself',
  noPrivilege: 'User does not have sufficient privilege to consent the permission',
  activationTokenInvalid: 'Invalid activation token',
  alreadyExisted: 'This user already existed in another organization',
  info: {
    updateSuccess: 'User info saved successfully',
  },
  duplicateEmailError: 'Same email already existed',

};

export const conditionalAccessPolicyMessage = {
  createSuccess: 'Conditional Access Policy created successfully',
  deleteSuccess: 'Conditional Access Policy deleted successfully',
};

export const organizationMessage = {
  updateSuccess: 'Organization saved successfully',
};

export const commonMessage = {
  somethingWrong: 'Something went wrong',
  copied: 'Copied',
};

export const attributeMessage = {
  deleteSuccess: 'Attribute deleted successfully',
  createSuccess: 'Attribute created successfully',
  updateSuccess: 'Attribute saved successfully',
  duplicateError: 'Same attribute already existed',
};

export const attributePassMessage = {
  deleteSuccess: 'Attribute Pass deleted successfully',
  createSuccess: 'Attribute Pass created successfully',
  updateSuccess: 'Attribute Pass saved successfully',
  duplicateError: 'Same attribute pass already existed',
};

export const attributeMappingMessage = {
  createSuccess: 'Attribute Mapping created successfully',
  deleteSuccess: 'Attribute Mapping deleted successfully',
  updateSuccess: 'Attribute Mapping saved successfully',
};

export const applicationMessage = {
  duplicateDomainError: 'Same Application URL already existed',
  createSuccess: 'Application created successfully',
  deleteSuccess: 'Application deleted successfully',
  updateSuccess: 'Application saved successfully',
  copySuccess: 'Application copied successfully',
  advancedSetting: {
    updateSuccess: 'Advanced Setting saved successfully',
  },
  kerberos: {
    updateSuccess: 'Kerberos Setting saved successfully',
  },
  ssl: {
    updateSuccess: 'SSL saved successfully',
    certExpired: 'The uploaded certificate has expired',
    certInvalid: 'The uploaded certificate is not valid',
    certKeyUnPair: 'The cert and the key are not paired'
  },
  template: {
    updateSuccess: 'Application Template saved successfully',
  },
};

export const otpGroupMessage = {
  createSuccess: 'Group created successfully',
  deleteSuccess: 'Group deleted successfully',
  updateSuccess: 'Group saved successfully',
};

export const mfaUserMessage = {
  createSuccess: 'User created successfully',
  deleteSuccess: 'User deleted successfully',
  updateSuccess: 'User saved successfully',
  nameDuplicate: 'Same username already existed',
  removeSuccess: 'User removed successfully',
  assignSuccess: 'User assigned successfully',
  importSuccess: 'User imported successfully'
};

export const deploymentMessage = {
  createSuccess: 'Deployment created successfully',
  deleteSuccess: 'Deployment deleted successfully',
  updateSuccess: 'Deployment saved successfully',
  reloadSuccess: 'Deployment configuration reloaded successfully',
  duplicateSuccess: 'Deployment duplicated successfully',
  template: {
    updateSuccess: 'Deployment Template saved successfully',
  },
  branding: {
    updateSuccess: 'Deployment branding saved successfully',
  },
  advancedSetting: {
    updateSuccess: 'Advanced Setting saved successfully',
  }
};

export const adminTokenMessage = {
  createSuccess: 'Admin Token created successfully',
  deleteSuccess: 'Admin Token deleted successfully',
};

export const ruleMessage = {
  createSuccess: 'Rule created successfully',
  deleteSuccess: 'Rule deleted successfully',
  updateSuccess: 'Rule saved successfully',
};

export const authorizerMessage = {
  createSuccess: 'Authorizer created successfully',
  deleteSuccess: 'Authorizer deleted successfully',
  updateSuccess: 'Authorizer saved successfully',
  nameDuplicate: 'Same authorizer name already existed',
};

export const ldapMessage = {
  createSuccess: 'LDAP created successfully',
  deleteSuccess: 'LDAP deleted successfully',
  updateSuccess: 'LDAP saved successfully',
  mapping: {
    createSuccess: 'LDAP Mapping created successfully',
    deleteSuccess: 'LDAP Mapping deleted successfully',
    updateSuccess: 'LDAP Mapping saved successfully',
  },
};

export const idpMessage = {
  createSuccess: 'IdP created successfully',
  deleteSuccess: 'IdP deleted successfully',
  updateSuccess: 'IdP saved successfully',
  unAttachSuccess: 'IdP unattached successfully',
  attachSuccess: 'IdP attached successfully',
  createAndAttachSuccess: 'IdP created and attached successfully',
  nameDuplicate: 'Same IdP name already existed',
  domainHintDuplicate: 'Same domain hint already existed',
};

export const mfaMessage = {
  createSuccess: 'MFA Configuration created successfully',
  deleteSuccess: 'MFA Configuration deleted successfully',
  updateSuccess: 'MFA Configuration saved successfully',
  unAttachSuccess: 'MFA Configuration unattached successfully',
  attachSuccess: 'MFA Configuration attached successfully',
  createAndAttachSuccess: 'MFA Configuration created and attached successfully',
  nameDuplicate: 'Same MFA Configuration name already existed',
};

export const provisioningKeyMessage = {
  createSuccess: 'Provisioning Key created successfully',
  deleteSuccess: 'Provisioning Key deleted successfully',
};

export const settingMessage = {
  updateSuccess: 'Setting saved successfully',
  maximumUsage: {
    updateSuccess: 'Maximum usage saved successfully',
  },
  authenticator: {
    resetSuccess: 'Authenticator reset successfully',
  },
};

export const certValidationErrMessage = {
  keyCertDoesMatch: 'Your key does not match cert.',
  validationFailed: 'Issues occurred when validating the file. You can still proceed if you have double checked the file.',
  certExpired: 'Your cert has expired.',
  publicDomainNotMatch: 'Your cert does not match Application URL',
  ignoreMessage: 'If you think this warning is incorrect, please ignore the message and continue.',
  keyEncrypted: 'Your private key is encrypted. You might need to remove key file password first.',
};

export const errorMessage = {
  maximumUsageError: 'It seems like you reached our limitation, which may cause your creation failed. You can contact our technical support to increase it',
};

export const generalMessage = {
  duplicateError: 'Duplicate Error',
};
