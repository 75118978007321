import amplitude from 'amplitude-js';
import { AMPLITUDE_ID } from './constants';
import { generateUUID } from './util';


class UserEventSender {
  private static instance: UserEventSender;

  constructor() {
    amplitude.getInstance().init(AMPLITUDE_ID);
  }

  public static getInstance(): UserEventSender {
    if (!UserEventSender.instance) {
      UserEventSender.instance = new UserEventSender();
    }
    return UserEventSender.instance;
  }

  setUserId(userId?: string) {
    amplitude.setUserId(userId || generateUUID());
  }
  
  send(message: string) {
    amplitude.getInstance().logEvent(message);
  }
}

export const userEventSender = UserEventSender.getInstance();
