// export const URL_PATTERN = new RegExp(
//     '^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
//     'i',
// );


export const URL_PATTERN = /^(?:(?:http|https|ftp):\/\/|\/\/)(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
export const URL_OR_URI_PATTERN = /^\/(?:[a-z0-9-._~!$&'()*+,;=:@\/]|%[0-9A-F]{2})*|((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)$/;
export const IP_PATTERN = /^(?:(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2]\d|3[0-2]))?$/;
// export const HOSTNAME_PATTERN = /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;
export const HOSTNAME_PATTERN = /^([a-zA-Z0-9-.]+)([a-zA-Z0-9])?(:[0-9]{1,5}){0,1}\/{0,1}$/;
export const ORGANIZATION_DOMAIN_PATTERN = /^([a-z0-9])([a-z0-9-.]*)([a-z0-9])$/;
export const RULE_URI_PATTERN = /^\/(?:[A-Za-z0-9-._~!$&'()*+,;=:@\/]|%[0-9A-F]{2})*$/;
export const ENDING_SLASH_OR_BACK_SLASH_PATTERN = /[^\/|\\]$/;
export const PUBLIC_DOMAIN_PATTERN = /^(http|https|wss|ws):\/\/([a-zA-Z0-9-.]+)([a-zA-Z-])?(:[0-9]+){0,1}\/{0,1}$/;
export const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const ATTRIBUTE_EXPRESSION_PATTERN = /^(map|replace)\("[a-zA-Z0-9-_@.,=]*","[a-zA-Z0-9-_@.,=]*"\)$/;

export const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
export const FILE_EXTENSION_PATTERN = /^[a-zA-Z0-9-_@.]+$/;
export const DNS_RESOLVERS_PATTERN = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9]){1}(\s(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9]))*$/;
