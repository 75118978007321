// TODO: 1. Add color variables 2. Add path alias

import { Provider } from 'mobx-react';
import { syncHistoryWithStore } from 'mobx-react-router';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import './App.scss';
import authentication from './auth/reactAzureAdb2c';
import { history as browserHistory } from './common/history';
import { observer } from './common/util';
import { MerlinLoading } from './component/MerlinLoading/MerlinLoading';
import rootRouter from './router/rootRouter';
import { globalStore } from './store/globalStore';
import * as mobxStore from './store/index';
import { routerStore } from './store/routerStore';
import { GlobalConfig } from './types/app';


const history = syncHistoryWithStore(browserHistory, routerStore);
declare global {
  interface Window {
    drift: any;
    driftt: any;
    config: GlobalConfig;
  }
}


const loadDrift = () => {
  const t = window.driftt = window.drift = window.driftt || [];
  if (!t.init) {
    if (t.invoked) {
      return;
    }
    t.invoked = !0;
    t.methods = ['identify', 'config', 'track', 'reset', 'debug', 'show', 'ping', 'page', 'hide', 'off', 'on'];
    t.factory = function(e: any) {
      return function(...args: any[]) {
        const n = Array.prototype.slice.call(args);
        return n.unshift(e), t.push(n), t;
      };
    };
    t.methods.forEach(function(e: any) {
      t[e] = t.factory(e);
    });
    t.load = function(t: any) {
      const e = 3e5; const n = Math.ceil(new Date() as any / e) * e; const o = document.createElement('script');
      o.type = 'text/javascript';
      o.async = !0;
      o.crossOrigin = 'anonymous';
      o.src = 'https://js.driftt.com/include/' + n + '/' + t + '.js';
      const i = document.getElementsByTagName('script')[0];
      i.parentNode && i.parentNode.insertBefore(o, i);
    };
  }

  window.drift.SNIPPET_VERSION = '0.3.1';
  window.drift.load('2a86f3u6dywd');
};

const LoginedFilter: FunctionComponent = () => {
  return (
    <Switch>
      {
        rootRouter.map(({ path, component, ...otherProps }) => (
          <Route
            path={path}
            component={component}
            {...otherProps}
            key={path}
          />
        ))
      }

    </Switch>
  );
};

const App: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const init = async () => {
    console.log('you are running under ' + process.env.NODE_ENV + ' mode');
    console.log('app render start with config', window.config);
    // all initialize should be done here
    setLoading(true);
    authentication.initialize();
    await authentication.run();
    if (window.config.ENABLE_DRIFT === 'true') {
      loadDrift();
    }
    setLoading(false);
    console.log('app render stop');
  };

  useEffect(() => {
    init();
  }, []);
  
  if (loading) {
    return (<MerlinLoading loading={loading} mask/>);
  }

  return (
    <Provider {...mobxStore}>
      <Loading />
      <Router history={history}>
        <Switch>
          <Redirect exact from="/" to="/home" />
          <LoginedFilter />
        </Switch>
      </Router>
    </Provider>
  );
};

const Loading = observer(() => {
  return (
    <MerlinLoading loading={globalStore.isGlobalLoading > 0} mask />
  );
});

export default App;
