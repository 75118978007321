import { UploadFile, UploadFileStatus } from 'antd/lib/upload/interface';

export class MerlinUploadFile implements UploadFile {
  uid: string;
  name: string;
  status?: UploadFileStatus | undefined;

  constructor(name: string) {
    this.uid = '0';
    this.name = name;
    this.status = 'done';
  }
}
