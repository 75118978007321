import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { FunctionComponent } from 'react';
import './MerlinLoading.scss';

interface MerlinLoadingProps {
    mask?: boolean;
    loading?: boolean;
}
const antIcon = <LoadingOutlined type="loading" style={{ fontSize: 30 }} />;

export const MerlinLoading: FunctionComponent<MerlinLoadingProps> = ({ loading = false, mask = false }) => {
  if (!loading) {
    return null;
  }

  return (
    <div className={`merlin-loading${mask ? ' mask' : ''}`}>
      <Spin size="large" indicator={antIcon}/>
    </div>
  );
};

export const MerlinCompLoading: FunctionComponent = () => {
  return (
    <div style={{ margin: '40px', textAlign: 'center' }}>
      <Spin size="large" indicator={antIcon}/>
    </div>
    
  );
};
