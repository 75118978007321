import md5 from 'md5';
import { MobXProviderContext } from 'mobx-react';
import moment from 'moment-timezone';
import forge from 'node-forge';
import queryString from 'query-string';
import React from 'react';
import { certValidationErrMessage } from '../i18n/i18n';
import { layoutRouter, superAdminMenu } from '../router/layoutRouter';
import rootRouter from '../router/rootRouter';
import { CreateIdpParams, UpdateIdPParams } from '../services/model/idp';
import { AlterName } from '../services/model/ssl';
import { routerStore } from '../store/routerStore';
import ErrorMs from '../types/ErrorMs';
import { INTERNAL_ERROR_CODE, MERLIN } from './constants';


/**
   * It will replace params' field into url, according reg format [:param]
   * @param url restful request url path
   * @param params request params
   * @return replaced url
   * @example
   *        url: /api/:test params: { test:'john', age: 12 }
   *        then return url: /api/john params: { age: 12 }
   */
export function pathIntercept(url: string, params?: Record<string, any>): { url: string; params: Record<string, any> | undefined } {
  if (!params) {
    return { url, params };
  }
  const match: RegExpMatchArray | null = url.match(/:\w*/g);
  if (!match) {
    return { url, params };
  }
  const arr: string[] = url.replace(/.*\/\//, '/').split('/');
  let path: string = url;
  let queryParam: Record<string, any> | undefined = { ...params };
  for (const val of arr) {
    if (val.includes(':')) {
      const valRm = val.replace(':', '');
      if (queryParam[valRm]) {
        path = path.replace(val, queryParam[valRm]);
        delete queryParam[valRm];
      }
    }
  }
  // When the object is empty, queryParma should set to undefined
  if (Object.keys(queryParam).length === 0) {
    queryParam = undefined;
  }
  return { url: path, params: queryParam };
}
/**
   * Append req params after ? 
   * 
   * @param url req url
   * @param params req params
   * @return url?query
   */
export const formateQueryParams = (url: string, params?: Record<string, string>): string => {
  let query = '';
  if (params) {
    query = '?' + queryString.stringify(params);
  }
  return url + query;
};

export const upperFirstLetterAndLowerOthers = (str: string): string => {
  if (!str) {
    return '';
  }
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

export const upperFirstLetterOnly = (str: string): string => {
  if (!str) {
    return '';
  }
  return str[0].toUpperCase() + str.slice(1);
};

// TODO: Add unit test to these functions
export function navigate(path: string, params?: object) {
  if (!path) {
    console.error('Please specify the pathname');
    return;
  }

  if (path === window.location.pathname) {
    routerStore.replace(path, params);
  } else {
    routerStore.push({
      pathname: path,
      state: params,
    });
  }
}

export function navigateInLayout(path: string, params?: object) {
  navigate(`${path}`, params);
}

export function navigateByRouteKey(key: string, params?: object, pathParams?: Record<string, string>) {
  let path = '';
  rootRouter.map((route) => {
    if (route.key === key) {
      path = route.path;
    }
    return route;
  });

  layoutRouter.map((route) => {
    if (route.key === key) {
      path = route.path;
    }
    return route;
  });

  superAdminMenu.map((route) => {
    if (route.key === key) {
      path = route.path;
    }
    return route;
  });

  path = pathIntercept(path, params).url;
  if (pathParams) {
    path = formateQueryParams(path, pathParams);
  }
  navigate(path, params);
}


export function useStores<T>(name: string): T {
  return React.useContext(MobXProviderContext)[name];
}

export { observer } from 'mobx-react';

export function getUserSession() {
  return JSON.parse(localStorage.getItem(MERLIN) as string);
}

/**
  * Remove empty value in Array,
  * this empty value can be a empty string or undefined or null
  *
  * @param arr - an Array
  * @returns after processing object
  */
export function removeEmpty(arr: Array<any> | undefined) {
  if (arr === undefined) return [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === '' || typeof (arr[i]) === 'undefined' || arr[i] === null) {
      arr.splice(i, 1);
      i = i - 1; //
    }
  }
  return arr;
};

/**
  * Remove empty value in Object,
  * this empty value can be a empty array or undefined or an empty object
  *
  * @param obj - an object
  * @returns after processing object
  */
export function removeEmptyValueInObject(obj: Record<string, any>) {
  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    if (val === undefined || val === '' || val === null) {
      delete obj[key];
    } else if (val instanceof Object && (val === undefined || Object.keys(val).length === 0)) {
      delete obj[key];
    } else if (val instanceof Array && val.length === 0) {
      delete obj[key];
    }
  });
  return obj;
}

/**
  * Use null to replace empty string
  *
  * @param obj - an object
  * @returns after processing object
*/
export function replaceEmptyStrWithNull(obj: Record<string, any>) {
  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    if (val === undefined || val === '') {
      obj[key] = null;
    }

    // else if (val instanceof Object && (val === undefined || Object.keys(val).length === 0)) {
    //   delete obj[key];
    // } else if (val instanceof Array && val.length === 0) {
    //   delete obj[key];
    // }
  });
  return obj;
}

/**
  * It will redirect to an error page and display the error message
  *
  * @param block - an block trigger the err, it will help to target the error position
  * @param err - any error object
  * @returns after processing object
*/
export const displayErrorPage = (block: string, err: any) => {
  const exceptionUrl = new URL(window.location.origin + '/exception');
  if (err instanceof ErrorMs) {
    exceptionUrl.searchParams.append('errorCode', err.code ? err.code.toString() : '400');
    exceptionUrl.searchParams.append('errorMessage', err.message ? block + err.message.toString() : block + 'Unknown Error');
  } else if (err instanceof Error) {
    exceptionUrl.searchParams.append('errorCode', INTERNAL_ERROR_CODE);
    exceptionUrl.searchParams.append('errorMessage', err.message ? block + err.message.toString() : block + 'Unknown Error');
  } else {
    exceptionUrl.searchParams.append('errorCode', INTERNAL_ERROR_CODE);
    exceptionUrl.searchParams.append('errorMessage', block + 'Unknown Error');
  }

  window.location.href = exceptionUrl.toString();
};

/**
  * Remove empty value in Object,
  * this empty value can be a null or undefined or an empty object
  *
  * @param obj - an object
  * @returns after processing object
  */
export const removeNullValueInObject = (obj: Record<string, any>) => {
  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    if (val === undefined || val === null) {
      delete obj[key];
    }
    if (val instanceof Object && Object.keys(val).length === 0) {
      delete obj[key];
    }
  });
  return obj;
};

/**
  * Trim string value in Object,
  * It can only trim the value at the first layer
  *
  * @param obj - an object
  * @returns after processing object
  */
export function trimValueInObject(obj: Record<string, any>) {
  Object.keys(obj).forEach((key) => {
    try {
      const val = obj[key];
      if (val === null) {
        return;
      }
      if (typeof val === 'string') {
        obj[key] = obj[key].trim();
      } else if (typeof val === 'object') {
        trimValueInObject(val);
      }
    } catch (e) {
      console.log('trim fail', key, obj[key]);
    }
  });
  return obj;
}

/**
  * It will scroll to the error position
  * It can only trim the value at the first layer
  *
  */
export const scrollToError = () => {
  const hasError = document.getElementsByClassName('has-error');
  if (hasError && hasError[0]) {
    hasError[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
  }
};

/**
   * Convert localTime to utc
   *
   * @param time - the time format is hh:mm
   * @param meridiem - 'AM' or 'PM'
   * @param timezone - the time is under this timezone
   * @returns utc time
   */
export function convertTimezoneToUTC(time: number, meridiem: string, timezone: string) {
  return moment.tz(`${time} ${meridiem}`, 'h A', timezone).utc().format('HH:mm:ss');
};

/**
  * Convert utc time to local time
  *
  * @param utcTime - the utc time, the format is HH:mm:ss
  * @param timezone - the timezone is going to convert
  * @returns converted time, format is {hour: hour, meridiem: meridiem}
  */
export function convertUTCToTimezone(utcTime: string, timezone: string): { hour: number; meridiem: string } {
  const time = moment.utc(utcTime, 'HH:mm:ss').tz(timezone);
  const hour = time.format('h');
  const meridiem = time.format('A');
  return {
    hour: parseInt(hour),
    meridiem: meridiem,
  };
}

export const convert12hTo24h = (hour: string, meridiem: string) => {
  return moment(`${hour} ${meridiem}`, 'h A').format('HH:mm:ss');
};

export const convert24hTo12h = (time: string): { hour: number; meridiem: string } => {
  const tmpTime = moment(time, 'HH:mm:ss');
  const hour = tmpTime.format('h');
  const meridiem = tmpTime.format('A');
  return {
    hour: parseInt(hour),
    meridiem: meridiem,
  };
};

export const isEmpty = (val: Record<string, any> | Array<any> | undefined): boolean => {
  if (val === undefined) {
    return true;
  }
  if (val instanceof Object) {
    return Object.keys(val).length === 0 ? true : false;
  } else {
    return (val as Array<any>).length === 0 ? true : false;
  }
};

export const replaceSpaceWithDash = (str: string): string => {
  return str.replace(/[\+\s&%#$@*]+/g, '-');
};

/**
  * Calculate a string size 
  * and display it in kb, mb, gb
  *
  * @param str - a normal string, e.g,. "abc"
  * @returns - a size string, e.g,. "13kb"
  */
export const calcFileSize = (size = 0) => {
  if (size === 0) {
    return 0;
  }
  const arr = ['bytes', 'KB', 'MB', 'GB', 'TB'];
  let sizeUnit = 0;

  while (size > 1024) {
    size /= 1024;
    ++sizeUnit;
  }
  return `${size.toFixed(0)}${arr[sizeUnit]}`;
};

export const calcMD5 = (str = '') => {
  return md5(str).slice(0, 10);
};

/**
  * Encode a string using hex
  * It is a internal use function
  *
  * @param str - a normal string, e.g,. "abc"
  * @returns a hex encoded string, e.g,. "006100620063"
  */
const hexEncode = (str: string) => {
  let hex; let i;
  let result = '';
  for (i = 0; i < str.length; i++) {
    hex = str.charCodeAt(i).toString(16);
    result += ('000' + hex).slice(-4);
  }
  return result;
};

/**
  * Decode a string using hex.
  * It is a internal use function
  *
  * @param str - an encoded string, e.g,. "006100620063"
  * @returns a decoded string, e.g,. "abc"
  */
const hexDecode = (str: string) => {
  let j;
  const hexes = str.match(/.{1,4}/g) || [];
  let back = '';
  for (j = 0; j < hexes.length; j++) {
    back += String.fromCharCode(parseInt(hexes[j], 16));
  }
  return back;
};

/**
  * Encode an uri param
  *
  * @param param - any uri param
  * @returns converted param into hex format, e,g. YXNKZGe=
  */
export const encodeURIParam = (param: string): string => {
  const encodedParam = window.btoa(param);
  console.log(encodedParam);

  return hexEncode(encodedParam);
};

export const decodeURIParam = (encodedParam: string): string => {
  const param = window.atob(hexDecode(encodedParam));
  console.log(param);
  return param;
};

export const uniqueArr = (arr: Array<any>): Array<any> => {
  return Array.from(new Set(arr));
};

export const generateUUID = (): string => {
  return ((new Date()).getTime() + Math.round(Math.random() * 1000)).toString();
};

export const calExpireTime = (months: number | undefined): number => {
  months = months ? months : 1;
  return moment.utc().add(months, 'months').unix() * 1000;
};

export const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero based. Add leading 0.
  const day = ('0' + date.getDate()).slice(-2); // Add leading 0.

  const formattedDate = year + '-' + month + '-' + day;

  return formattedDate;
};

export const getQueryVariable = (hash: string, variable: string): string => {
  const vars = hash.replace('#', '').split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return '';
};

export const getParams = () => {
  const vars = window.location.search.replace('?', '').split('&');
  const result: Record<string, any> = {};
  vars.forEach((value) => {
    const pair = value.split('=');
    result[pair[0]] = pair[1];
  });
  return result;
};

export const randomString = (length = 8) => {
  // Declare all characters
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  // Pick characers randomly
  let str = '';
  for (let i = 0; i < length; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return str;
};

export const getRSA2text = (buffer: ArrayBuffer, isPrivate = 0) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  const base64 = window.btoa(binary);
  let text = '-----BEGIN ' + (isPrivate ? 'PRIVATE' : 'PUBLIC') + ' KEY-----\n';
  text += base64.replace(/[^\x00-\xff]/g, '$&\x01').replace(/.{64}\x01?/g, '$&\n');
  text += '\n-----END ' + (isPrivate ? 'PRIVATE' : 'PUBLIC') + ' KEY-----';
  return text;
};

export const getPortFromPublicDomain = (publicDomain: string): number => {
  return publicDomain?.split(':')[2] ? parseInt(publicDomain?.split(':')[2]) : (publicDomain?.split(':')[0] === 'https' ? 443 : 80);
};

export const getHostnameFromPublicDomain = (publicDomain: string | undefined) => {
  if (!publicDomain) {
    return '';
  }
  if (publicDomain.includes('://')) {
    return publicDomain.split(':')[1].substring(2);
  }
  return '';
};

export const compareCNWithHostname = (commonName: string, publicDomain: string): boolean => {
  return new RegExp('^' + commonName.replace(/\*/g, '.*') + '$').test(publicDomain);
};

export const compareModulus = (privateModulus: number[], publicModulus: number[]) => {
  return privateModulus.toString() === publicModulus.toString();
};

/**
  * Wait for a period of time, 
  *
  * @param delay - unit is milliseconds
  * @returns converted param into hex format, e,g. YXNKZGe=
  */
export const wait = (delay = 0) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
};

export const extractHostnameFromUrl = (url: string) => {
  const urlObj = new URL(url);
  return urlObj.hostname;
};

export const checkPublicKey = (publicKeyText: any, publicDomain?: string) => {
  try {
    const pki = forge.pki;
    // 1. check format
    const cert = pki.certificateFromPem(publicKeyText);

    // 2. check date
    const { notBefore, notAfter } = cert.validity;
    const fingerprint = pki.getPublicKeyFingerprint(cert.publicKey, {
      // md: forge.md.sha256.create(),
      encoding: 'hex',
    });
    console.log('notBefore', cert, notBefore, notAfter, fingerprint);
    const now = new Date().valueOf();
    if (now < notBefore.valueOf() || now > notAfter.valueOf()) {
      throw new Error(certValidationErrMessage.certExpired);
    }

    // 3. check domain match
    if (!publicDomain) {
      return;
    }
    let commonName;
    cert.subject.attributes.forEach((attr) => {
      if (attr.name === 'commonName') {
        commonName = attr.value;
      }
    });
    if (!commonName) {
      const altName = cert.getExtension('subjectAltName') as AlterName;
      commonName = altName.altNames[0].value;
    }
    console.log('common name', commonName);
    if (!compareCNWithHostname(commonName, getHostnameFromPublicDomain(publicDomain))) {
      throw new Error(certValidationErrMessage.publicDomainNotMatch);
    }
  } catch (e) {
    if (e instanceof Error) {
      if (e.message.includes('Your cert')) {
        throw e;
      } else {
        throw new Error(certValidationErrMessage.validationFailed);
      }
    }
  }
};

export const checkPrivateKey = (keyFileText: any, certFileText: any) => {
  try {
    const pki = forge.pki;
    // 1. check format
    const privateKey = pki.privateKeyFromPem(keyFileText);

    // 2. check pair
    if (!certFileText) {
      return;
    }
    const publicKey: any = pki.certificateFromPem(certFileText).publicKey;
    if (!compareModulus(privateKey.n.data, publicKey.n.data)) {
      throw new Error(certValidationErrMessage.keyCertDoesMatch);
    }

    // OK
  } catch (e) {
    if (e instanceof Error) {
      // throw e;
      if (e.message.includes('is encrypted')) {
        throw new Error(certValidationErrMessage.keyEncrypted);
      }
      throw new Error(certValidationErrMessage.validationFailed);
    }
  }
};

/**
  * Covert camel case to snake case
  *
  * @param data - CreateIdpParams
  * @returns converted into protocolProperties' + '[' + key + ']'
  */
export const flatProtocolProperties = (data: CreateIdpParams | UpdateIdPParams) => {
  const newData: Record<string, any> = { ...data };
  const protocolProperties: Record<string, any> | undefined = data.protocolProperties;
  protocolProperties && Object.keys(protocolProperties).forEach((key: string) => {
    const newKey = 'protocolProperties' + '[' + key + ']';
    newData[newKey] = protocolProperties[key];
  });
  delete newData.protocolProperties;
  return newData;
};

/**
  * Covert camel case to snake case
  *
  * @param str - camel case string, e.g., camelCase
  * @returns converted into snake case string, e.g., CAMEL_CASE
  */
export const camelToSnakeCase = (str: string | undefined) => str ? str.replace(/[A-Z]/g, (letter) => `_${letter.toUpperCase()}`) : undefined;

/**
  * Covert snake case to camel case
  *
  * @param str - snake case string, e.g., snake_case or SNAKE_CASE
  * @returns converted into camel case string, e.g., CamelCase
  */
export const snakeToCamelCase = (str: string | undefined) => str ? upperFirstLetterOnly(str.toLowerCase().replace(/([-_][a-z])/g, (group) =>
  group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
)) : undefined;


/**
  * Convert javascript dot notation object to nested object
  *
  * @param obj - dot notation object, e.g., {'ab.cd.e' : 'foo'}
  * @returns converted into nested object, e.g., {ab: {cd: {e:'foo', f:'bar'}, g:'foo2'}}
  */
export function deepen(obj: Record<string, any>) {
  const result: Record<string, any> = {};

  // For each object path (property key) in the object
  // eslint-disable-next-line guard-for-in
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split('.');

    // Create sub-objects along path as needed
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      if (part) {
        target = target[part] = target[part] || {};
      }
    }

    // Set value at end of path
    target[parts[0]] = obj[objectPath];
  }

  return result;
}
