import { message } from 'antd';
import { action, observable } from 'mobx';
import { AuditLogRes, GetAuditLogParams } from '../services/model/overview';
import { User } from '../services/model/user';
import Services from '../services/services';

const DEFAULT_PAGE_SIZE = 6;
export class AuditLogStore {
  private static instance: AuditLogStore;
  

  public static getInstance(): AuditLogStore {
    if (!AuditLogStore.instance) {
      AuditLogStore.instance = new AuditLogStore();
    }

    return AuditLogStore.instance;
  }

  @observable auditLogData: Partial<AuditLogRes> = {};
  @observable actorList: Partial<Array<User>> = [];
  @observable currentGetAuditLogParams: Partial<GetAuditLogParams> = {}

  @action 
  async getAuditLogActors() {
    this.actorList = (await Services.getAuditLogActors()).data;
  }

  @action
  async getAuditLog(payload: GetAuditLogParams) {
    if (!payload.size) {
      payload.size = DEFAULT_PAGE_SIZE;
    }
    this.currentGetAuditLogParams = payload;
    try {
      this.auditLogData = await Services.getAuditLogs(payload);
    } catch (err) {
      if (err instanceof Error) {
        message.error(err.message);
        this.auditLogData = {};
      }
    }
  }
}

export const auditLogStore = AuditLogStore.getInstance();
