import Icon from '@ant-design/icons';
import React from 'react';
import { ReactComponent as ApiSvg } from '../../assets/images/icon/API.svg';
import { ReactComponent as B2BSvg } from '../../assets/images/icon/b2b.svg';
import { ReactComponent as EBSSvg } from '../../assets/images/icon/ebs.svg';
import { ReactComponent as EmrSvg } from '../../assets/images/icon/EMR.svg';
import { ReactComponent as LdapSvg } from '../../assets/images/icon/ldap.svg';
import { ReactComponent as OpenNewTabSvg } from '../../assets/images/icon/open-new-tab.svg';
import { ReactComponent as PeopleSoftSvg } from '../../assets/images/icon/peoplesoft.svg';
import { ReactComponent as PointDownSvg } from '../../assets/images/icon/point-down.svg';
import { ReactComponent as WebSvg } from '../../assets/images/icon/Web.svg';

export const OpenNewTabIcon = (props: any) => <Icon component={OpenNewTabSvg} {...props} />;
export const EmrIcon = (props: any) => <Icon component={EmrSvg} {...props} />;
export const B2BIcon = (props: any) => <Icon component={B2BSvg} {...props} />;
export const PointDownIcon = (props: any) => <Icon component={PointDownSvg} {...props} />;
export const EBSIcon = (props: any) => <Icon component={EBSSvg} {...props} />;
export const PeopleSoftIcon = (props: any) => <Icon component={PeopleSoftSvg} {...props} />;
export const WebIcon = (props: any) => <Icon component={WebSvg} {...props} />;
export const ApiIcon = (props: any) => <Icon component={ApiSvg} {...props} />;
export const LdapIcon = (props: any) => <Icon component={LdapSvg} {...props} />;

// export default OpenNewTabIcon;
