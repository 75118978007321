import { message } from 'antd';
import { action, observable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../common/constants';
import { provisioningKeyMessage } from '../i18n/i18n';
import { CreateProvisionKeyParams, ProvisionKey, ProvisionKeysRes } from '../services/model/provision-key';
import Services from '../services/services';

const tokenModalDefaultValue= {
  visible: false,
  okText: 'Create',
  apiKeyDisplay: false,
};
export class ProvisionKeyStore {
  private static instance: ProvisionKeyStore;

  public static getInstance() {
    if (!ProvisionKeyStore.instance) {
      ProvisionKeyStore.instance = new ProvisionKeyStore();
    }

    return ProvisionKeyStore.instance;
  }
  @observable deploymentId = '';

  @observable keyPairModal = Object.assign(tokenModalDefaultValue);
  @observable keyPairData: Partial<ProvisionKeysRes> = {};

  @observable createdApiKey: Partial<ProvisionKey> = {};

  async init() {
    await this.getData(0);
  }

  convertTime() {
    return this.keyPairData.data!.forEach((token) => {
      token.creationDate = new Date(token.creationDate).toLocaleString();
      token.expire = new Date(token.expire).toLocaleString();
    });
  }

  @action
  initModalState = () => {
    this.keyPairModal = Object.assign(tokenModalDefaultValue);
  }

  @action
  showModal = () => {
    this.initModalState();
    this.keyPairModal.visible = true;
  };

  @action
  handleOk = async (payload: CreateProvisionKeyParams) => {
    if (!this.keyPairModal.apiKeyDisplay) {
      console.log(payload);
      await this.createData(payload);
    } else {
      this.keyPairModal.visible = false;
      this.init();
    }
  };

  @action
  handleCancel = (e: any) => {
    this.keyPairModal.visible = false;
    if (this.keyPairModal.apiKeyDisplay) {
      this.init();
    }
  };

  @action
  getData = async (page: number) => {
    await Services.getProvisionKeys({
      page,
      size: DEFAULT_PAGE_SIZE,
      deploymentId: this.deploymentId,
    }).then((res) => {
      console.log(res);
      runInAction(() => {
        this.keyPairData = res;
        this.convertTime();
      });
    }).catch((e) => {
      message.error(e.message);
    });
  }

  @action
  createData = async (payload: CreateProvisionKeyParams) => {
    await Services.createProvisionKey(payload).then((res) => {
      runInAction(() => {
        this.createdApiKey = res.data;
        this.keyPairModal.apiKeyDisplay = true;
        this.keyPairModal.okText = 'OK, got it';
      });
    }).catch((e) => {
      message.error(e.message);
    });
  }

  @action
  updateData = (value: object) => {
    alert('Updated a token');
  }

  @action
  deleteData = (apiKeyId: number) => {
    Services.revokeProvisionKey({ apiKeyId: apiKeyId }).then((res) => {
      console.log(res);
      if (res.errCode === 0) {
        this.init();
        message.success(provisioningKeyMessage.deleteSuccess);
      } else {
        message.error(res.errMsg);
      }
    }).catch((e) => {
      message.error(e.message);
    });
  }
}

export const provisionKeyStore = ProvisionKeyStore.getInstance();
