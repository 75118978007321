import { message } from 'antd';
import { action, observable } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../common/constants';
import { attributeMappingMessage, attributeMessage } from '../i18n/i18n';
import { Attribute, AttributesRes, UpdateAttributeMapping, UpdateAttributeParams } from '../services/model/attribute';
import { IdP } from '../services/model/idp';
import Services from '../services/services';
import { idpsStore } from './idpsStore';

export class AttrMappingStore {
  private static instance: AttrMappingStore;


  public static getInstance(): AttrMappingStore {
    if (!AttrMappingStore.instance) {
      AttrMappingStore.instance = new AttrMappingStore();
    }

    return AttrMappingStore.instance;
  }


  @observable mappings: { [key: string]: Array<string> } = {};
  idpAttributeIds: { [key: string]: Array<string> } = {};
  @observable currentActiveIdpIndex = 0;
  @observable appAttributes: Array<Attribute> = [];
  @observable appAttributeTable: Partial<AttributesRes> = {};
  @observable idpsOfApp: IdP[] = [];
  @observable loading = false;

  @action
  async init(appId: string) {
    this.currentActiveIdpIndex = 0;
    this.getAppAttributes(0, appId);
    await this.getAppAttributes(0, appId, true);
    await idpsStore.getIdpsList(appId);
    this.getAttributeMappings(appId, idpsStore.idpsOfApplicationList!);
  }

  // this.mappings = {
  //   lastName: ['ass', 'qqe', null],
  //   firstName: ['wqe', 's', 'asdwq'],
  //   displayName: ['assad', 'asd', 'asdda'],
  //   phone: ['sadds', null, null],
  // }
  @action
  async getAttributeMappings(appId: string, idps: Array<IdP>) {
    await Services.getAttributeMappings({ applicationId: appId }).then((res) => {
      console.log('get attribute mappings', res);
      const tmpMappings: { [key: string]: Array<string> } = {};
      this.idpAttributeIds = {};
      this.appAttributes.map((attribute) => {
        tmpMappings[attribute!.field] = [];
        this.idpAttributeIds[attribute!.field] = [];
      });
      res.data.map((mapping) => {
        let idpIndex = 0;
        idps.map((idp, index) => {
          if (idp.id === mapping.idpId) {
            idpIndex = index;
          }
        });
        if (mapping.ldapId) {
          mapping.idpAttributeField = `dwldap_${mapping.ldapId}_${mapping.idpAttributeField}`;
        }
        tmpMappings[mapping.applicationAttributeField][idpIndex] = mapping.idpAttributeField;
        this.idpAttributeIds[mapping.applicationAttributeField][idpIndex] = mapping.idpAttributeId;
      });
      this.mappings = { ...tmpMappings };
      console.log('mappings', this.mappings);
    }).catch((err) => {
      message.error(err.message);
    });
  }

  async getIdps(appId: string) {
    try {
      this.idpsOfApp = (await Services.getIdPsOfApplication({ appId, page: 0 })).data;
      this.currentActiveIdpIndex = 0;
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  }

  @action
  async updateAttributeMappings() {
    const payload: Array<UpdateAttributeMapping> = [];
    Object.keys(this.mappings).map((appAttributeField) => {
      this.mappings[appAttributeField].map((idpAttributeField: string, index: number) => {
        const idpAttributeId = this.idpAttributeIds[appAttributeField][index];
        let ldapId = undefined;
        if (idpAttributeField && idpAttributeField.indexOf('dwldap') >= 0) {
          ldapId = idpAttributeField.split('_')[1];
          idpAttributeField = idpAttributeField.split('_').slice(2).join('');
        }
        payload.push({
          idpAttributeId,
          idpAttributeField,
          ldapId,
        });
      });
    });
    await Services.updateAttributeMappings(payload).then(() => {
      message.success(attributeMappingMessage.updateSuccess);
    }).catch((err) => {
      message.error(err.message);
    });
  }

  @action
  async getAppAttributes(page: number, appId: string, all?: boolean) {
    this.loading = true;
    try {
      const res = await Services.getAppAttributes({
        page,
        size: all ? undefined : DEFAULT_PAGE_SIZE,
        appId,
      });
      if (all) {
        this.appAttributes = res.data;
      } else {
        this.appAttributeTable = res;
      }
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
    this.loading = false;
  }

  @action
  async addAppAttribute(payload: Attribute) {
    await Services.addAppAttribute(payload).then(() => {
      message.success(attributeMessage.createSuccess);
    }).catch((err) => {
      message.error(err.message);
    });
  }

  @action
  async updateAppAttribute(payload: UpdateAttributeParams) {
    await Services.updateAppAttribute(payload).then(() => {
      message.success(attributeMessage.updateSuccess);
    }).catch((err) => {
      message.error(err.message);
    });
  }

  @action
  async deleteAppAttribute(attributeId: string) {
    await Services.deleteAppAttribute({ attributeId }).then(() => {
      message.success(attributeMessage.deleteSuccess);
    }).catch((err) => {
      message.error(err.message);
    });
  }
}

export const attrMappingStore = AttrMappingStore.getInstance();
