import { ErrorMessage } from './app';

export default class ErrorMs extends Error implements ErrorMessage {
  public message: string;
  public code: string | number;
  
  constructor(message: string, code: string | number) {
    super(message);
    this.code = code;
    this.message = message;
  }
}
