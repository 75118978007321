import 'whatwg-fetch';
import authentication from '../../auth/reactAzureAdb2c';
import { MerlinUploadFile } from '../../class/MerlinUploadFile';
import { GET_ACTIVITIES } from '../../common/api';
import { HttpMethod } from '../../common/constants';
import { displayErrorPage, formateQueryParams, navigateByRouteKey, pathIntercept, upperFirstLetterAndLowerOthers } from '../../common/util';
import { applicationMessage, attributeMessage, attributePassMessage, generalMessage, idpMessage, mfaUserMessage, userMessage } from '../../i18n/i18n';
import { userStore } from '../../store';
import ErrorMs from '../../types/ErrorMs';

/**
 * Request abstract class.
 */
abstract class ResourceAbs {
  public domain: string | undefined;
  /**
   * Domain
   *
   * @param domain - Domain used by request.
   */
  constructor(domain: string | undefined) {
    this.domain = domain;
  }
  // public abstract get<T>(url: string, params?: Record<string, string>, headers?: Record<string, string>): Promise<T>;
  // public abstract post<T>(url: string, params?: Record<string, any>, headers?: Record<string, string>): Promise<T>;
  // public abstract delete<T>(url: string, params?: Record<string, any>, headers?: Record<string, string>): Promise<T>;
  // public abstract put<T>(url: string, params?: Record<string, any>, headers?: Record<string, string>): Promise<T>;
  // protected abstract request<T>(url: string, params: ResponseInit): Promise<T>;
}


type ResourceDataError = {errCode: number; errMsg: string};

/**
 * API Request Class
 */
class Resource extends ResourceAbs {
  isRefreshing = true;
  subscribers: Array<Function> = [];

  private addSubscriber(callback: Function) {
    this.subscribers.push(callback);
  }

  private refreshTokenRequest() {
    // let subscribers = this.subscribers;
    authentication.refreshAccessToken(() => {
      this.subscribers.forEach((callback) => {
        callback();
      });
      this.subscribers = [];
    });
  }

  private simpleRetryRequests() {
    this.subscribers.forEach((callback) => {
      callback();
    });
    this.subscribers = [];
  }

  private checkStatus(response: Response, url: string, method: HttpMethod, params?: RequestInit | FormData): Promise<any> | Response {
    if (url.includes(GET_ACTIVITIES)) {
      return response;
    }

    if (response && (response.status === 403 || response.status >= 500)) {
      // refresh token and need a sign to prevent repeat request
      const retryOriginalRequest = new Promise((resolve) => {
        this.addSubscriber(() => {
          if (params instanceof FormData) {
            resolve(this.upload(url, params, method));
          } else {
            resolve(this.request(url, method, params));
          }
        });
      });
      
      if (this.isRefreshing) {
        response.status === 403 ? this.refreshTokenRequest() : this.simpleRetryRequests();
      }
      this.isRefreshing = false;
      
      return retryOriginalRequest;
    } else {
      return response;
    }
  }
  /**
   * GET Request
   *
   * @param url request url
   * @param params request params
   * @param headers request headers
   * @return Promise type response
   */
  public get<T extends ResourceDataError>(url: string, params?: Record<string, any>, headers?: Record<string, string>): Promise<T> {
    const pathParams = pathIntercept(url, params);
    return this.request<T>(formateQueryParams(pathParams.url, pathParams.params), HttpMethod.GET, { method: 'GET', headers });
  }

  /**
   * POST Request
   *
   * @param url request url
   * @param params request params
   * @param headers request headers
   * @return Promise type response
   */
  public post<T extends ResourceDataError>(url: string, params?: Record<string, any>, headers?: Record<string, string>, urlParams?: Record<string, any>): Promise<T> {
    const pathParams = pathIntercept(url, params);
    return this.request<T>(formateQueryParams(pathParams.url, urlParams), HttpMethod.POST, { method: 'POST', headers, body: JSON.stringify(pathParams.params) });
  }

  /**
   * POST Request
   *
   * @param url request url
   * @param params request params
   * @param headers request headers
   * @return Promise type response
   */
  public async upload<T extends ResourceDataError>(url: string, params?: Record<string, any>, method?: string): Promise<T> {
    const token: string | undefined = userStore.getToken();
    let headers: Record<string, any> = { 'Authorization': token ? `Bearer ${token}` : '' };
    if (userStore.currentOrganizationId) {
      headers = { ...headers, ['organization-id']: userStore.currentOrganizationId };
    }

    const formData = new FormData();
    if (params) {
      Object.keys(params).map((key) => {
        if (params[key] !== undefined) {
          if (params[key] instanceof Object && !(params[key] instanceof File)) {
            if (!(params[key] instanceof MerlinUploadFile)) {
              formData.append(key, JSON.stringify(params[key]));
            }
          } else {
            formData.append(key, params[key]);
          }
        }
      });
    }

    const pathParams = pathIntercept(url, params);
    const options = { method: method ? method : 'POST', body: formData, headers };
    const response: Response = await fetch(pathParams.url, options).then((res) => this.checkStatus(res, url, HttpMethod.UPLOAD, params));
    return this.handleData<T>(response, HttpMethod.UPLOAD);
  }

  /**
   * DELETE Request
   *
   * @param url request url
   * @param params request params
   * @param headers request headers
   * @return Promise type response
   */
  public delete<T extends ResourceDataError>(url: string, params?: Record<string, any>, headers?: Record<string, string>): Promise<T> {
    const pathParams = pathIntercept(url, params);
    return this.request<T>(formateQueryParams(pathParams.url, pathParams.params), HttpMethod.DELETE, { method: 'DELETE', body: JSON.stringify(pathParams.params), headers });
  }

  /**
   * PUT Request
   *
   * @param url request url
   * @param params request params
   * @param headers request headers
   * @return Promise type response
   */
  public put<T extends ResourceDataError>(url: string, params?: Record<string, any>, headers?: Record<string, string>): Promise<T> {
    const pathParams = pathIntercept(url, params);
    return this.request<T>(pathParams.url, HttpMethod.PUT, { method: HttpMethod.PUT, headers, body: JSON.stringify(pathParams.params) });
  }

  /**
   * PATCH Request
   *
   * @param url request url
   * @param params request params
   * @param headers request headers
   * @return Promise type response
   */
  public patch<T extends ResourceDataError>(url: string, params?: {query?: Record<string, any>; body?: Record<string, any>}, headers?: Record<string, string>): Promise<T> {
    if (params) {
      const pathParams = params.query ? pathIntercept(url, params.query) : pathIntercept(url, params.body);
      return this.request<T>(
          formateQueryParams(pathParams.url, params.query ? pathParams.params : undefined),
          HttpMethod.PATCH, 
          { method: HttpMethod.PATCH, headers, body: params.body ? JSON.stringify(pathParams.params) : undefined }
      );
    } else {
      return this.request<T>(
          formateQueryParams(url), 
          HttpMethod.PATCH, 
          { method: HttpMethod.PATCH, headers }
      );
    }
  }

  /**
   * Basic request
   *
   * @param url request url
   * @param params request params
   * @return Promise type response
   */
  protected async request<T extends ResourceDataError>(url: string, method: HttpMethod, params?: RequestInit): Promise<T> {
    const defaultHeaders: Record<string, any> = {
      'Content-Type': 'application/json',
      'Authorization': userStore.getToken() ? 'Bearer ' + userStore.getToken() : null,
    };
    let headers = { ...defaultHeaders };
    if (userStore.currentOrganizationId) {
      headers = { ...headers, ['organization-id']: userStore.currentOrganizationId };
    }
    if (params?.headers) {
      headers = { ...headers, ...params.headers };
    }
    const requestParams = { ...params, headers };
    const response: Response = await fetch(this.domain + url, requestParams).then((res) => this.checkStatus(res, url, method, params)).catch((err) => console.log(err.errCode));
    return this.handleData<T>(response, method);
  }


  /**
   * If the interface status is abnormal and the data returned with code indicates that the error is treated as an exception  
   *
   * @param response response
   * @return to json format
   */
  public async handleData<T extends ResourceDataError>(response: Response | T, method: HttpMethod): Promise<T> {
    if (response instanceof Response) {
      if (response.status >= 200 && response.status <= 300) {
        let data: T;
        try {
          data = await response.json();
        } catch {
          throw new ErrorMs('Cannot decode response maybe its JSON format is invalid', '0002');
        }
        this.isRefreshing = true;
        if (data.errCode === 0) {
          return data;
        } else if (data.errCode === 8 || data.errCode === 9) {
          let resource;
          if (data.errMsg.includes('creation.count')) {
            resource = data.errMsg.split('.')[3];
          }
          throw new ErrorMs(`${resource ? upperFirstLetterAndLowerOthers(resource) + ' count' : 'Resource count'} exceeds the maximum limitation, please contact our technical support to increase the limitation`, '0008');
        } else if (data.errCode === 18) {
          navigateByRouteKey('no-access', undefined, { errorMsg: 'Your account has been deleted' });
          throw new ErrorMs('Your account has been deleted', data.errCode);
        } else if (data.errCode === 19) {
          throw new ErrorMs('Send invitation too frequency. Please try again later', data.errCode);
        } else if (data.errCode === 11) {
          if (data.errMsg.includes('username.duplicate')) {
            throw new ErrorMs(mfaUserMessage.nameDuplicate, '0011');
          } else if (data.errMsg.includes('name.duplicate')) {
            throw new ErrorMs(idpMessage.nameDuplicate, '0011');
          } else if (data.errMsg.includes('email.duplicated')) {
            throw new ErrorMs(userMessage.duplicateEmailError, '0013');
          } else if (data.errMsg.includes('field.duplicated')) {
            throw new ErrorMs(attributeMessage.duplicateError, '0014');
          } else if (data.errMsg.includes('attribute-pass.duplicated')) {
            throw new ErrorMs(attributePassMessage.duplicateError, '0014');
          } else if (data.errMsg.includes('public.domain.duplicated')) {
            throw new ErrorMs(applicationMessage.duplicateDomainError, '0012');
          } else {
            throw new ErrorMs(generalMessage.duplicateError, '0012');
          }
        } else if (data.errCode === 1000) {
          displayErrorPage('Request Method Error: ', new ErrorMs(data.errMsg, data.errCode));
        } else if (data.errCode === 1011) {
          navigateByRouteKey('no-access');
          throw new ErrorMs('You have no access to this resource', data.errCode);
        } else if (data.errCode === 3001 && method === HttpMethod.GET) {
          if (data.errMsg.includes('organization')) {
            throw new ErrorMs(data.errMsg, data.errCode);
          } else {
            navigateByRouteKey('not-found');
            throw new ErrorMs('The resource has been deleted or does not exist', data.errCode); 
          }
        } else if (data.errCode === 6002) {
          if (data.errMsg.includes('invalid.signInAudience')) {
            throw new ErrorMs('Invalid Sign In Audience', data.errCode);
          } else {
            throw new ErrorMs('Invalid cookie domain which must be part of public domain', data.errCode);
          }
        }
        throw new ErrorMs(data.errMsg, data.errCode);
      } else if (response.status === 403) {
        throw new ErrorMs('Session Expired', '0001');
      } else if (response.status === 404) {
        displayErrorPage('Fetch API Error: ', new ErrorMs('This API does not exist, you can go back to the home page or try again later.', 404));
      } 
      throw new ErrorMs('Internet Error', '0000');
    } else {
      const data: T = response;
      return data;
    }
  }
}

export const RESOURCE = new Resource('');
