import { message, Modal } from 'antd';
import { action, observable } from 'mobx';
import { MerlinUploadFile } from '../class/MerlinUploadFile';
import { AzureAccountSignInAudienceEnum, AzureAccountSignInAudienceForBackendEnum, AZURE_ONE_CLICK_SCOPES, IdPTypeEnum, SSOProtocolEnum, SSOProvidersEnum } from '../common/constants';
import { flatProtocolProperties, snakeToCamelCase } from '../common/util';
import { commonMessage, idpMessage } from '../i18n/i18n';
import { graphAPIServices } from '../services/graphAPIServices';
import { CreateApplicationInAzureADParams } from '../services/model/graphAPIService';
import { AddIdPModalProps, CreateIdpParams, IdP, IdPsRes, UpdateIdPParams } from '../services/model/idp';
import Services from '../services/services';
import { attrMappingStore } from './attrMappingStore';
import { userStore } from './userStore';


const DEFAULT_PAGE_SIZE_OF_IDPS_TABLE = 5;

export class IdpsStore {
  private static instance: IdpsStore;

  public static getInstance(): IdpsStore {
    if (!IdpsStore.instance) {
      IdpsStore.instance = new IdpsStore();
    }

    return IdpsStore.instance;
  }


  @observable modal: AddIdPModalProps = {
    visible: false, type: 'CREATE', currentData: undefined, idpType: IdPTypeEnum.GENERAL
  };

  @observable idpsOfApplicationTable: Partial<IdPsRes> = {};
  @observable idpsOfApplicationList: Array<IdP> = [];

  @observable allIdpsTable: Partial<IdPsRes> = {};
  @observable allIdPsList: Array<IdP> = [];

  @observable currentIdpIndex = 0

  async reloadIdpTable(appId?: string) {
    if (appId) {
      await this.getIdpTable(0, appId);
      attrMappingStore.init(appId);
    } else {
      await this.getIdpTable(0);
    }
  }


  // If page is 0, then load all idps
  @action
  async getIdpTable(page: number, appId?: string, all?: boolean): Promise<any> {
    this.currentIdpIndex = 0;
    if (appId) {
      try {
        const res = await Services.getIdPsOfApplication({
          page,
          size: DEFAULT_PAGE_SIZE_OF_IDPS_TABLE,
          appId,
          type: IdPTypeEnum.GENERAL,
        });
        this.idpsOfApplicationTable = res;
      } catch (err) {
        if (err instanceof Error) {
          message.error(err.message);
        }
      }
    } else {
      try {
        const allIdPsRes = await Services.getIdPs({
          page,
          size: all ? undefined : DEFAULT_PAGE_SIZE_OF_IDPS_TABLE,
        });
        this.allIdpsTable = allIdPsRes;
        return allIdPsRes;
      } catch (err) {
        if (err instanceof Error) {
          message.error(err.message);
        }
      }
    }
  }

  @action
  async getIdpsList(appId?: string, successCallback?: Function): Promise<IdPsRes | undefined> {
    try {
      if (!appId) {
        const idpsRes = await Services.getIdPs({ page: 0, size: 5000 });
        this.allIdPsList = idpsRes.data;
        if (successCallback) {
          successCallback();
        }
        return idpsRes;
      }
      const idpsOfAppRes = await Services.getIdPsOfApplication({
        page: 0,
        size: 5000,
        appId,
      });
      this.idpsOfApplicationList = idpsOfAppRes.data;
      if (this.idpsOfApplicationList && this.idpsOfApplicationList.length > 0) {
        this.idpsOfApplicationList[0].active = true;
      }
      if (successCallback) {
        successCallback();
      }
      return idpsOfAppRes;
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  }

  @action
  setCurrentIdp(currentIdp: IdP) {
    this.modal.currentData = {
      ...currentIdp,
      keyFile: currentIdp.protocolProperties.key_file_name ? new MerlinUploadFile(currentIdp.protocolProperties.key_file_name) : undefined,
      certFile: currentIdp.protocolProperties.cert_file_name ? new MerlinUploadFile(currentIdp.protocolProperties.cert_file_name) : undefined,
    };
  }

  discoverB2CIssuer = async (data: CreateIdpParams): Promise<string> => {
    const b2cDomain = data.protocolProperties.b2c_domain;
    const policyName = data.protocolProperties.policy_name;
    const tenantId = data.protocolProperties.tenant_id;
    try {
      if (!b2cDomain || !policyName || !tenantId) {
        throw new Error(commonMessage.somethingWrong);
      }
      const discoverIssuerRes = await graphAPIServices.discoverIssuerInB2C(b2cDomain, policyName, tenantId);
      if (discoverIssuerRes) {
        return discoverIssuerRes.issuer;
      } else {
        throw new Error(commonMessage.somethingWrong);
      }
    } catch (err) {
      throw new Error('Something went wrong, please double check your tenant name and policy name');
    }
  };

  @action
  async createIdp(data: CreateIdpParams) {
    try {
      if (data.protocol === SSOProtocolEnum.OIDC && data.protocolProperties.provider === SSOProvidersEnum.B2C) {
        data.protocolProperties.issuer = await this.discoverB2CIssuer(data);
      }
      const res= await Services.createIdP(flatProtocolProperties(data));
      return res;
    } catch (e) {
      throw e;
    }
  }

  @action
  async getIdpDetail(idpId: string) {
    try {
      const res = await Services.getIdpDetail({ idpId });
      return res;
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  }

  @action
  async deleteIdp(idpId: string) {
    await Services.deleteIdP({ idpId });
    message.success(idpMessage.deleteSuccess);
  }

  @action
  async updateIdp(data: UpdateIdPParams) {
    try {
      await Services.updateIdP(flatProtocolProperties(data));
    } catch (e) {
      throw e;
    }
  }

  @action
  async deAssignIdp(appId: string, idpId: string) {
    await Services.unAttachIdP({ appId, idpId }).then(() => {
      this.reloadIdpTable(appId);
      message.success(idpMessage.unAttachSuccess);
    }).catch((err) => {
      message.error(err.message);
    });
  }

  @action
  async assignIdpToApp(appId: string, idpId: string, type: IdPTypeEnum) {
    try {
      await Services.assignIdP({ appId, idpId, type });
    } catch (e) {
      throw e;
    }
  }

  @action
  async createAndAssignIdp(appId: string, data: CreateIdpParams, type: IdPTypeEnum) {
    try {
      const createRes = await this.createIdp(data);
      await Services.assignIdP({ appId, idpId: createRes.data.id, type });
    } catch (err) {
      throw err;
    }
  }

  @action
  async createCustomButton(appId: string, data: CreateIdpParams) {
    try {
      await Services.createAndAssignIdP({
        appId,
        ...data,
        customButton: true,
        protocol: SSOProtocolEnum.OIDC,
        ['protocolProperties[provider]']: SSOProvidersEnum.GENERIC,
      });
    } catch (err) {
      throw err;
    }
  }


  createAppInAzureAD = async (
    displayName = 'Default app name',
    signInAudienceForBackend: AzureAccountSignInAudienceForBackendEnum = AzureAccountSignInAudienceForBackendEnum.AZURE_AD_MY_ORG,
    publicDomain = 'http://localhost:9772',
    tenantId?: string,
  ) => {
    try {
      if (!(userStore.azureAccessToken && graphAPIServices.validateAzureAccessToken(AZURE_ONE_CLICK_SCOPES, tenantId))) {
        await graphAPIServices.acquireAccessTokenSilent(AZURE_ONE_CLICK_SCOPES, tenantId);
      }
    } catch (e) {
      throw e;
    }

    const signInAudience = snakeToCamelCase(signInAudienceForBackend) as AzureAccountSignInAudienceEnum;
    console.log('sign in audience', signInAudience);
    console.log('sign in audience for backend', signInAudienceForBackend);

    const payload: CreateApplicationInAzureADParams = {
      displayName,
      signInAudience,
      web: {
        redirectUris: [`${publicDomain}/datawiza/authorization-code/callback`],
      },
    };
    tenantId = tenantId ? tenantId : graphAPIServices.getTenantIdFromAzureAccessToken();

    const waitForContinue = () => new Promise<void>((resolve, reject) => {
      Modal.confirm({
        title: 'Warning',
        content: 'We detect that it seems like you are using a personal account. Currently we do not support using one-click to create application without tenant id. If you are invited by another tenant or you know the tenant id, please input the tenant id and try again. If you can make sure you are not using a personal account, please ignore this message, and click the continue button.',
        okText: 'Continue',
        okButtonProps: {
          danger: true,
        },
        onOk() {
          resolve();
        },
        onCancel() {
          reject(new Error('You cancel the creation'));
        },
      });
    });

    try {
      if (!tenantId && graphAPIServices.isPersonalAccount()) {
        await waitForContinue();
      }
      const addApplicationInAzureRes = await graphAPIServices.createApplicationInAzureAD(payload);
      if (addApplicationInAzureRes) {
        const addPasswordInAzureRes = await graphAPIServices.createPasswordInAzure(addApplicationInAzureRes.id, displayName + ' key');
        let addServicePrincipalRes;
        if (signInAudience !== AzureAccountSignInAudienceEnum.PERSONAL_MICROSOFT_ACCOUNT) {
          addServicePrincipalRes = await graphAPIServices.createServicePrincipal(addApplicationInAzureRes.appId);
        }
        if (addPasswordInAzureRes && (addServicePrincipalRes || signInAudience === AzureAccountSignInAudienceEnum.PERSONAL_MICROSOFT_ACCOUNT)) {
          const tmpTenantId = (() => {
            switch (signInAudience) {
              case AzureAccountSignInAudienceEnum.AZURE_AD_MULTIPLE_ORGS: return 'organizations';
              case AzureAccountSignInAudienceEnum.AZURE_AD_MY_ORG: return tenantId;
              case AzureAccountSignInAudienceEnum.AZURE_AD_AND_PERSONAL_MICROSOFT_ACCOUNT: return 'common';
              case AzureAccountSignInAudienceEnum.PERSONAL_MICROSOFT_ACCOUNT: return 'consumers';
              default: return tenantId;
            }
          })();
          return {
            appIdInAzure: addApplicationInAzureRes.id,
            tmpTenantId,
            clientId: addApplicationInAzureRes.appId,
            clientSecret: addPasswordInAzureRes.secretText,
            issuer: `https://login.microsoftonline.com/${tmpTenantId}/v2.0`,
          };
        } else {
          await graphAPIServices.deleteApplicationInAzure(addApplicationInAzureRes.id);
          throw new Error('Something went wrong');
        }
      }
      throw new Error('Something went wrong');
    } catch (err) {
      throw err;
    }
  };
}

export const idpsStore = IdpsStore.getInstance();
