import { message } from 'antd';
import { action, observable, runInAction } from 'mobx';
import { FieldTagTypesEnum } from '../common/constants';
import { generateUUID, removeEmptyValueInObject } from '../common/util';
import { ruleMessage } from '../i18n/i18n';
import * as RuleMgmtRes from '../services/model/rule';
import { CreateRuleParams } from '../services/model/rule';
import Services from '../services/services';
import { FieldComp } from '../types/app';
import ErrorMs from '../types/ErrorMs';


enum RuleType {
  ACCESS = 'ACCESS',
  REDIRECT = 'REDIRECT'
}

const DEFAULT_PAGE_SIZE = 10;
export class RuleStore {
  private static instance: RuleStore;

  public static getInstance() {
    if (!RuleStore.instance) {
      RuleStore.instance = new RuleStore();
    }

    return RuleStore.instance;
  }
  @observable loading = true;
  @observable currentRuleData: Partial<RuleMgmtRes.Rule> = {};
  @observable fieldComps: Array<FieldComp> = [];
  // {
  //   id: '1',
  //   name: 'Rule 1',
  //   description: 'Only engineer can access this resource',
  //   urls: ['http://example.com/path'],
  //   emails: {oneof: ['123@gmail.com', '56], endwith: ['data.com']}
  //   periods: [{start: '8:00:00', end: '9:00:00'}],
  //   ipSections: ['127.0.0.1/31'],
  //   methods: ['GET'],
  //   ruleBase: {
  //     Role: 'Engineer',
  //   },
  //   action: 'ALLOW',
  //   tags: ['Time', 'IP'],
  // }
  @observable ruleData: Partial<RuleMgmtRes.RulesRes> = {};

  @observable defaultRuleBases: Record<string, string> = {};
  @observable customRuleBases: Record<string, string> = {};

  @action init(ruleId?: string, successCallback?: Function) {
    // Fetch data and init form
    if (ruleId) {
      // BUG: getRuleDetail will be called twice
      this.getRuleDetail(ruleId, successCallback);
    }
  }

  @action
  getRuleDetail = (ruleId: string, successCallback?: Function) => {
    this.loading = true;
    Services.getRuleDetail({ ruleId: ruleId }).then((res) => {
      runInAction(() => {
        this.currentRuleData = res.data;
        removeEmptyValueInObject(this.currentRuleData);
        this.fieldComps = [];
        let data: Partial<FieldComp> = {};
        console.log('before normalize', res.data);
        Object.keys(this.currentRuleData).forEach((key) => {
          switch (key) {
            case 'emails':
              const emails = this.currentRuleData[key];
              emails && Object.keys(emails).forEach((key, index) => {
                data = {
                  name: FieldTagTypesEnum.EMAIL,
                  value: {
                    logic: key,
                    values: emails[key],
                  },
                  uuid: generateUUID(),
                };
                if (index > 0) {
                  data.isNotFirst = true;
                }
                this.fieldComps.push(data);
              });
              break;
            case 'groups':
              data = {
                name: FieldTagTypesEnum.GROUP,
                value: this.currentRuleData[key],
                uuid: generateUUID(),
              };
              this.currentRuleData[key] && this.fieldComps.push(data);
              break;
            case 'scopeConditions':
              data = {
                name: FieldTagTypesEnum.SCOPE,
                value: this.currentRuleData[key],
                uuid: generateUUID(),
              };
              this.currentRuleData[key] && this.fieldComps.push(data);
              break;
            case 'ruleBase':
              const ruleBases = this.currentRuleData[key];
              ruleBases && Object.keys(ruleBases).forEach((key, index) => {
                data = {
                  name: FieldTagTypesEnum.USER_ATTR,
                  value: {
                    ruleBase: key,
                    ruleBaseValue: ruleBases[key],
                  },
                  uuid: generateUUID(),
                };
                if (index > 0) {
                  data.isNotFirst = true;
                }
                this.fieldComps.push(data);
              });
              break;
            case 'periods':
              const periods = this.currentRuleData[key];
              periods && periods.forEach((value: any, index: number) => {
                data = {
                  name: FieldTagTypesEnum.TIME,
                  value: value,
                  uuid: generateUUID(),
                };
                if (index > 0) {
                  data.isNotFirst = true;
                }
                this.fieldComps.push(data);
              });
              break;
            case 'ipSections':
              data = {
                name: FieldTagTypesEnum.IP,
                value: this.currentRuleData[key],
                uuid: generateUUID(),
              };
              this.currentRuleData[key] && this.fieldComps.push(data);
              break;
          }
        });
        console.log('after normalize', { ...this.currentRuleData });
        if (successCallback) {
          successCallback();
        }

        this.loading = false;
      });
    }).catch((e) => {
      message.error(e.errMsg);
      this.loading = false;
    });
  }

  @action
  getAccessRules = async (page: number, appId: string) => {
    await Services.getAppRules({
      page,
      size: DEFAULT_PAGE_SIZE,
      ruleType: RuleType.ACCESS,
      appId
    }).then((res) => {
      console.log(res);
      runInAction(() => {
        this.ruleData = res;
        if (this.ruleData.data) {
          this.serializeData(this.ruleData.data);
        }
        this.loading = false;
      });
    }).catch((e) => {
      message.error(e.message);
    });
  }

  getRedirectRules = async (page: number, appId: string) => {
    try {
      const res = await Services.getAppRules({
        page,
        size: DEFAULT_PAGE_SIZE,
        ruleType: RuleType.REDIRECT,
        appId
      });
      return res;
    } catch (e) {
      if (e instanceof ErrorMs) {
        message.error(e.message);
      }
    }
  }

  @action
  createRule = async (createRuleParams: CreateRuleParams, appId: string, ruleType: RuleType, successCallback?: Function) => {
    // before send data, reorganize it first

    // send the create request
    try {
      await Services.createRule({ ...createRuleParams, appId, ruleType });
      if (successCallback) {
        successCallback();
      }
      message.success(ruleMessage.createSuccess);
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  }

  @action
  updateRule = async (createRuleParams: CreateRuleParams, ruleId: string, successCallback?: Function) => {
    try {
      await Services.updateRule(Object.assign(createRuleParams, { ruleId: ruleId }));
      if (successCallback) {
        successCallback();
      }
      message.success(ruleMessage.updateSuccess);
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  }

  @action
  deleteRule = async (ruleId: string) => {
    await Services.deleteRule({ ruleId: ruleId }).then(((res) => {
      message.success(ruleMessage.deleteSuccess);
    })).catch((err) => {
      message.error(err.message);
    });
  }

  @action
  addCustomUserAttr = (value: string) => {
    this.customRuleBases = Object.assign({ [value]: value }, this.customRuleBases);
  }

  serializeData(rules: RuleMgmtRes.Rule[]) {
    // remove empty
    rules.map((rule) => {
      removeEmptyValueInObject(rule);
    });
    // add tags
    rules.map((rule) => {
      rule.tags = [];
      Object.keys(rule).forEach((key) => {
        switch (key) {
          case 'ruleBase':
            rule.tags.push(FieldTagTypesEnum.USER_ATTR);
            break;
          case 'urls':
            rule.tags.push(FieldTagTypesEnum.URL);
            break;
          case 'periods':
            rule.tags.push(FieldTagTypesEnum.TIME);
            break;
          case 'ipSections':
            rule.tags.push(FieldTagTypesEnum.IP);
            break;
          case 'groups':
            rule.tags.push(FieldTagTypesEnum.GROUP);
            break;
        }
      });
    });
  }
}

export const ruleStore = RuleStore.getInstance();
