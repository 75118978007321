import { message } from 'antd';
import { action, observable } from 'mobx';
import { userStore } from '.';
import { DEFAULT_PAGE_SIZE } from '../common/constants';
import { authorizerMessage } from '../i18n/i18n';
import { Authorizer, AuthorizersRes, CreateAuthorizer } from '../services/model/authorizer';
import Services from '../services/services';


export class AuthorizerStore {
  private static instance: AuthorizerStore;

  public static getInstance(): AuthorizerStore {
    if (!AuthorizerStore.instance) {
      AuthorizerStore.instance = new AuthorizerStore();
    }

    return AuthorizerStore.instance;
  }


  @observable modal: {visible: boolean; type: 'CREATE' | 'EDIT'; currentAuthorizer: Authorizer | undefined} = {
    visible: false, type: 'CREATE', currentAuthorizer: undefined,
  };
  @observable currentAuthorizerIndex = 0;
  @observable authorizersTable: Partial<AuthorizersRes> = {}
  @observable authorizers: Array<Authorizer> = []

  @action
  async initAuthorizerTable() {
    await this.getAuthorizers(0);
    await userStore.getUsageInfo();
  }

  @action
  async getAuthorizers(page: number) {
    this.currentAuthorizerIndex = 0;
    await Services.getAuthorizers({
      page,
      size: DEFAULT_PAGE_SIZE,
    }).then((res) => {
      this.authorizersTable = res;
    }).catch((err) => {
      message.error(err.message);
    });
  }

  getAuthorizerTypeById(id: string) {
    return this.authorizers.find((authorizer) => authorizer.id === id)?.type;
  }

  @action
  getAllAuthorizers() {
    Services.getAuthorizers({
      page: 0,
    }).then((res) => {
      this.authorizers = res.data;
    });
  }

  updateAuthorizer(payload: CreateAuthorizer, authorizerId: string, successCallback: Function) {
    Services.updateAuthorizer({ ...payload, authorizerId }).then(() => {
      if (successCallback) {
        successCallback();
      }
      if (this.authorizersTable.data) {
        this.authorizersTable.data[this.currentAuthorizerIndex] = { ...payload, id: authorizerId };
      }
      
      message.success(authorizerMessage.updateSuccess);
    }).catch((err) => {
      if (err.code === '0011') {
        message.error(authorizerMessage.nameDuplicate);
      } else {
        message.error(err.message);
      }
    });
  }

  createAuthorizer(payload: CreateAuthorizer, successCallback?: Function) {
    Services.createAuthorizer(payload).then((res) => {
      if (successCallback) {
        successCallback(res);
      }
      this.initAuthorizerTable();
      message.success(authorizerMessage.createSuccess);
    }).catch((err) => {
      if (err.code === '0011') {
        message.error(authorizerMessage.nameDuplicate);
      } else {
        message.error(err.message);
      }
    });
  }

  deleteAuthorizer(authorizerId: string) {
    Services.deleteAuthorizer({ authorizerId }).then(() => {
      this.initAuthorizerTable();
      message.success(authorizerMessage.deleteSuccess);
    }).catch((err) => {
      message.error(err.message);
    });
  }

  getCurrentAuthorizerId() {
    return this.authorizersTable.data ? this.authorizersTable.data[this.currentAuthorizerIndex].id : '';
  }

  getAuthorizerDetail(authorizerId: string) {
    return Services.getAuthorizerDetail({ authorizerId });
  }

  @action
  setModalType(type: 'CREATE' | 'EDIT') {
    this.modal.type = type;
    if (this.modal.type === 'EDIT') {
      this.modal.currentAuthorizer = this.authorizersTable.data![this.currentAuthorizerIndex];
    } else {
      this.modal.currentAuthorizer = undefined;
    }
  }
}

export const authorizerStore = AuthorizerStore.getInstance();
