import { message, Modal } from 'antd';
import { action, observable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../common/constants';
import { navigateByRouteKey } from '../common/util';
import { deploymentMessage } from '../i18n/i18n';
import { Branding, CreateDeploymentParams, Deployment, DeploymentAdvancedFormInputs, DeploymentsRes, UpdateDeploymentTemplatePrams } from '../services/model/deployment';
import Services from '../services/services';
import ErrorMs from '../types/ErrorMs';
import { userStore } from './userStore';


const { confirm } = Modal;

export class DeploymentsStore {
  private static instance: DeploymentsStore;

  public static getInstance(): DeploymentsStore {
    if (!DeploymentsStore.instance) {
      DeploymentsStore.instance = new DeploymentsStore();
    }

    return DeploymentsStore.instance;
  }

  @observable currentDeployment: Partial<Deployment> = {};
  @observable deploymentsData: Partial<DeploymentsRes> = {};
  @observable loading = true;

  async init() {
    await this.getDeployments(0);
  }

  @action
  createDeployment(deploymentData: CreateDeploymentParams) {
    this.loading = true;
    deploymentData.name = deploymentData.name.trim();
    Services.createDeployment(deploymentData).then((res) => {
      this.loading = false;
      runInAction(() => {
        this.currentDeployment.id = res.data.id;
        navigateByRouteKey('deployment-detail', { deploymentId: res.data.id, tab: 'general' });
      });
    }).catch((e) => {
      this.loading = false;
      message.error(e.message);
    });
  }

  @action
  async getDeployments(page: number) {
    try {
      this.deploymentsData = await Services.getDeployments({
        page,
        size: DEFAULT_PAGE_SIZE,
      });
    } catch (e) {
      if (e instanceof Error) {
        this.deploymentsData = {};
        message.error(e.message);
      }
    }
  }

  @action
  async getDeploymentDetail(deploymentId: number) {
    try {
      const deploymentDetailRes = await Services.getDeploymentDetail({ deploymentId });
      if (deploymentDetailRes) {
        this.currentDeployment = deploymentDetailRes.data;
      }
      const applicationsRes = await Services.getApplicationsOfDeployment({ deploymentId, page: 0 });
      if (applicationsRes) {
        this.currentDeployment.applications = applicationsRes.data;
      }
    } catch (err) {
      if (err instanceof ErrorMs) {
        message.error(err.message);
      }
    }
  }

  async duplicate(deploymentId: number, name: string, successCallback: Function) {
    try {
      await Services.duplicateDeployment({ deploymentId, name });
      message.success(deploymentMessage.duplicateSuccess);
      successCallback();
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  }


  @action
  async getDeploymentDetailByExpand(deployment: Deployment) {
    await Services.getDeploymentDetail({ deploymentId: deployment.id }).then((res) => {
      deployment.abStatuses = res.data.abStatuses;
      deployment.statusLight = res.data.statusLight;
    });

    return new Promise((resolve, reject) => {
      resolve(deployment);
    });
  }

  @action
  async deleteDeployment(deploymentId: number, cascadeDeleteIdps: boolean) {
    try {
      await Services.deleteDeployment({ deploymentId, cascadeDeleteIdps });
      this.init();
      userStore.getUsageInfo();
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  }

  @action
  async updateDeployment(deploymentData: Deployment, deploymentId: string, successCallback?: Function, failureCallback?: Function) {
    try {
      await Services.updateDeployment({ ...deploymentData, deploymentId });
      message.success(deploymentMessage.updateSuccess);
      if (successCallback) {
        successCallback();
      }
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
        if (failureCallback) {
          failureCallback();
        }
      }
    }
  }

  async updateTemplate(payload: UpdateDeploymentTemplatePrams, deploymentId: string) {
    await Services.updateDeploymentTemplate({ ...payload, deploymentId }).then(() => {
      message.success(deploymentMessage.template.updateSuccess);
    }).catch((e) => {
      message.error(e.message);
    });
  }

  async getTemplate(deploymentId: string) {
    return await Services.getDeploymentTemplate({ deploymentId });
  }

  async updateBranding(branding: Branding, deploymentId: string) {
    try {
      if (typeof branding.background === 'string') {
        branding.isBackgroundNullified = false;
        delete branding.background;
      } else if (branding.background instanceof File) {
        branding.isBackgroundNullified = false;
      } else {
        branding.isBackgroundNullified = true;
      }

      if (typeof branding.logo === 'string') {
        branding.isLogoNullified = false;
        delete branding.logo;
      } else if (branding.logo instanceof File) {
        branding.isLogoNullified = false;
      } else {
        branding.isLogoNullified = true;
      }
      
      await Services.updateBranding({ ...branding, deploymentId });
      message.success(deploymentMessage.branding.updateSuccess);
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  }

  async getBranding(deploymentId: string) {
    return await Services.getBranding({ deploymentId });
  }

  async updateAdvancedSetting(deploymentId: string, payload: DeploymentAdvancedFormInputs) {
    try {
      await Services.updateDeploymentAdvancedSetting({ ...payload, deploymentId });
      message.success(deploymentMessage.advancedSetting.updateSuccess);
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    } 
  }

  getAdvancedSetting(deploymentId: string) {
    return Services.getDeploymentAdvancedSetting({ deploymentId });
  }
}

export const deploymentsStore = DeploymentsStore.getInstance();
