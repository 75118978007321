/* eslint-disable react/display-name */
/* eslint-disable new-cap */
import React from 'react';
import Loadable from 'react-loadable';
import { MerlinLoading } from '../component/MerlinLoading/MerlinLoading';

const rootRouter = [
  {
    path: '/activation',
    key: 'activation',
    exact: true,
    component: Loadable({
      loader: () => import('../page/Activation/Activation'),
      loading: () => <MerlinLoading />,
    }),
  },
  {
    path: '/exception',
    key: 'exception',
    exact: true,
    component: Loadable({
      loader: () => import('../page/Exception/Exception'),
      loading: () => <MerlinLoading />,
    }),
  },
  {
    path: '/cb',
    key: 'cb',
    exact: true,
    component: Loadable({
      loader: () => import('../page/IdPTestCallback/IdPTestCallback'),
      loading: () => <MerlinLoading />,
    }),
  },
  {
    path: '/getting-started',
    key: 'getting-started',
    exact: true,
    component: Loadable({
      loader: () => import('../page/GettingStarted/GettingStarted'),
      loading: () => <MerlinLoading />,
    }),
  },
  {
    path: `/`,
    key: 'basic',
    exact: false,
    component: Loadable({
      loader: () => import('../layout/BasicLayout'),
      loading: () => <MerlinLoading />,
    }),
  },

];

export default rootRouter;
