import { message, Modal } from 'antd';
import { action, observable } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../common/constants';
import { ldapMessage } from '../i18n/i18n';
import { CreateLdapTestCaseParams, LDAP, LdapMapping, LDAPsRes, LdapTestCaseInvalidRes, LdapTestCaseValidRes, UnassignedIdp, UpdateLdapParams } from '../services/model/ldap';
import Services from '../services/services';


const { confirm } = Modal;
const TEST_CASE_TIMEOUT_TIME = 50000; // 50s


export class DirectoryStore {
  private static instance: DirectoryStore;

  public static getInstance(): DirectoryStore {
    if (!DirectoryStore.instance) {
      DirectoryStore.instance = new DirectoryStore();
    }

    return DirectoryStore.instance;
  }


  @observable currentDirectory: Partial<LDAP> = {};
  @observable currentLdapNames: Array<Partial<LDAP>> = [];
  @observable directoryListData: Partial<LDAPsRes> = {};
  @observable currentLdapMappings: Array<LdapMapping> = [];
  @observable unassignedIdPList: Array<UnassignedIdp> = [];
  @observable loading = false;
  @observable currentLdapTestCaseId = '';
  @observable testCaseStatus: 'VALID' | 'INVALID' | 'TIMEOUT' | 'PENDING' | null = null;
  @observable testCaseValidRes: Partial<LdapTestCaseValidRes> = {};
  @observable testCaseInvalidRes: Partial<LdapTestCaseInvalidRes> = {};
  testCaseInterval: any = null;
  testCaseTimeout: any = null;

  async init(deploymentId: string) {
    await this.getDirectoryList(0, undefined, deploymentId);
  }

  @action
  updateLdapDirectory(payload: UpdateLdapParams, successCallback?: Function) {
    Services.updateLDAP(payload).then(() => {
      message.success('Update LDAP Configuration success');
      if (successCallback) {
        successCallback();
      }
    }).catch((err) => {
      message.error(err.message);
    });
  }

  @action
  async getDirectoryList(page: number, pageSize: number = DEFAULT_PAGE_SIZE, deploymentId: string) {
    this.loading = true;
    await Services.getLDAPs({ page, size: pageSize, hasProfile: true }).then((res) => {
      this.directoryListData = res;
    }).catch((err) => {
      message.error(err.message);
    });
    this.loading = false;
  }

  @action
  getLdapDetail(ldapId: string) {
    Services.getLDAP({ ldapId }).then((res) => {
      this.currentDirectory = res.data;
    }).catch((err) => {
      message.error(err.message);
    });
  }

  @action
  async getLdapNames() {
    await Services.getLDAPs({ hasProfile: false, deploymentStatus: 'OK' }).then((res) => {
      this.currentLdapNames = res.data;
    });
  }

  @action
  createLdapMapping(payload: LdapMapping, ldapId: string) {
    Services.createLdapMapping({ ...payload, ldapId }).then((res) => {
      this.getLdapMappings(ldapId);
      message.success(ldapMessage.mapping.createSuccess);
    }).catch((err) => {
      message.error(err.message);
    });
  }

  @action
  updateLdapMapping(payload: LdapMapping, ldapId: string, mappingId: string) {
    console.log('try to update', mappingId);
    Services.updateLdapMapping({ ...payload, mappingId }).then((res) => {
      this.getLdapMappings(ldapId);
      message.success(ldapMessage.mapping.updateSuccess);
    }).catch((err) => {
      message.error(err.message);
    });
  }

  @action
  getLdapMappings(ldapId: string) {
    this.loading = true;
    Services.getLdapMappings({ ldapId }).then((res) => {
      this.currentLdapMappings = [];
      this.unassignedIdPList = [];
      res.data.forEach((mapping: any) => {
        if (mapping.uuid) {
          this.currentLdapMappings.push(mapping);
        } else {
          this.unassignedIdPList.push(mapping as UnassignedIdp);
        }
      });
      this.loading = false;
    }).catch((err) => {
      message.error(err.message);
      this.loading = false;
    });
  }

  @action
  deleteLdapMapping(ldapId: string, idpId: string) {
    Services.deleteLdapMapping({ ldapId, idpId }).then(() => {
      this.getLdapMappings(ldapId);
      message.success(ldapMessage.mapping.deleteSuccess);
    }).catch((err) => {
      message.error(err.message);
    });
  }

  @action
  getDirectoryDetail(directoryId: string, successCallback?: Function) {
    Services.getLDAP({ ldapId: directoryId }).then((res) => {
      this.currentDirectory = { ...res.data, ldapId: directoryId };
      this.currentLdapNames = [{ ...res.data, ldapId: directoryId }];
      if (successCallback) {
        successCallback();
      }
    }).catch((err) => {
      message.error(err.message);
    });
  }
 
  @action
  deleteDirectory(directoryId: string, deploymentId: string) {
    confirm({
      title: 'Do you want to delete this LDAP?',
      okButtonProps: { danger: true },
      okText: 'Delete',
      onOk: async (resolve, reject) => {
        try { 
          await Services.deleteLDAP({ ldapId: directoryId })
          message.success(ldapMessage.deleteSuccess);
          this.init(deploymentId);
          resolve();
        } catch (e) {
          if (e instanceof Error) {
            message.error(e.message);
          }
          resolve();
        }
      },
    });
  }

  @action
  createLdapTestCase(payload: CreateLdapTestCaseParams) {
    payload.name = this.currentLdapNames.filter((ldapName) => ldapName.id == payload.ldapId)[0].name!;
    payload.deploymentId = this.currentLdapNames.filter((ldapName) => ldapName.id == payload.ldapId)[0].deploymentId!;
    this.resetLdapTestCaseStatus();
    Services.createLdapTestCase({ ...payload }).then((res) => {
      console.log('test case id is: ', res.data.id);
      this.testCaseStatus = 'PENDING';
      this.currentLdapTestCaseId = res.data.id;
      this.testCaseInterval = setInterval(() => {
        console.log('every second request ldap test case result');
        Services.getLdapTestCase({ testCaseId: this.currentLdapTestCaseId }).then((res) => {
          if (res.data.valid === true) {
            clearInterval(this.testCaseInterval);
            clearTimeout(this.testCaseTimeout);
            this.testCaseValidRes = res.data;
            this.testCaseStatus = 'VALID';
          } else if (res.data.valid === false) {
            this.testCaseInvalidRes = res.data;
            clearInterval(this.testCaseInterval);
            clearTimeout(this.testCaseTimeout);
            this.testCaseStatus = 'INVALID';
          }
        });
      }, 5000);
      this.testCaseTimeout = setTimeout(() => {
        console.log('5s later clear testCaseInterval');
        clearInterval(this.testCaseInterval);
        this.testCaseStatus = 'TIMEOUT';
      }, TEST_CASE_TIMEOUT_TIME);
    });
  }

  @action
  resetLdapTestCaseStatus() {
    this.testCaseStatus = null;
    this.testCaseTimeout = null;
    this.testCaseInterval = null;
  }
}

export const directoryStore = DirectoryStore.getInstance();
