import * as Msal from '@azure/msal-browser';
import jwtDecode, { InvalidTokenError } from 'jwt-decode';
import { AZURE_CONDITIONAL_ACCESS_SCOPES, B2C_ID_TOKEN_ACR, CLIENT_ID } from '../common/constants';
import { wait } from '../common/util';
import { commonMessage } from '../i18n/i18n';
import { userStore } from '../store';
import ErrorMs from '../types/ErrorMs';
import { CreateApplicationInAzureADParams, CreateApplicationInAzureADRes, CreatePasswordInAzureRes, GetConditionalAccessPoliciesRes, GetServicePrincipalRes } from './model/graphAPIService';

type IdTokenClaims = {
  acr: string;
  scp: string;
}

class GraphAPIServices {
  private static instance: GraphAPIServices;

  public static getInstance(): GraphAPIServices {
    if (!GraphAPIServices.instance) {
      GraphAPIServices.instance = new GraphAPIServices();
    }

    return GraphAPIServices.instance;
  }

  private handleData = async (response: Response) => {
    if (response.status === 201 || response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 204) {
      return null;
    } else {
      const errorRes: { error: { code: string; message: string } } = await response.json();
      if (errorRes.error.code === 'InvalidUriScheme') {
        throw new ErrorMs('Your Application Url must start with "HTTPS" or "http://localhost"', errorRes.error.code);
      }
      throw new ErrorMs(errorRes.error.message, errorRes.error.code);
    }
  }

  private handleError = (err: Error) => {
    console.log('get azure access token error', err);
    // throw err;
    if (err instanceof Msal.InteractionRequiredAuthError && err.errorMessage.includes('AADSTS')) {
      const errMsg = err.errorMessage.split('\n')[0].split(':')[1];
      throw errMsg;
    } else if (err instanceof InvalidTokenError) {
      throw new Error(commonMessage.somethingWrong);
    } else if (err instanceof Msal.BrowserAuthError) {
      if (err.message.includes('user_cancelled')) {
        throw new Error('User canalled the flow');
      }
      throw new Error('User does not have sufficient privilege to consent the permission');
    } else if (err.message.includes('contains invalid applications')) {
      throw new Error('Policy contains invalid application id, please double check your IdP configuration');
    } else if (err instanceof Msal.AuthError && err.message.includes('could not resolve endpoints')) {
      throw new Error('Could not resolve endpoints. Please check network or your tenant id and try again');
    } else {
      throw err;
    }
  }

  private getParams = (method: 'GET' | 'POST' | 'DELETE', payload?: Record<string, any>, customHeaders?: Record<string, string>) => {
    const params = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userStore.azureAccessToken,
        ...customHeaders,
      },
      method,
      body: JSON.stringify(payload),
    };
    return params;
  }

  public acquireAccessTokenSilent = async (scopes: string[], tenantId?: string) => {
    const authority = tenantId ? `https://login.microsoftonline.com/${tenantId}` : 'https://login.microsoftonline.com/common';
    const configuration: Msal.Configuration = {
      auth: {
        clientId: CLIENT_ID,
        authority,
        redirectUri: `${window.location.origin}/azure-callback`,
      },
      
    };
    const azureMsal = await Msal.PublicClientApplication.createPublicClientApplication(configuration);
    const allUsers = azureMsal.getAllAccounts();
    let graphAPIUser = null;
    allUsers.forEach((user) => {
      const idTokenClaims = user.idTokenClaims as IdTokenClaims;
      if (idTokenClaims && !B2C_ID_TOKEN_ACR.includes(idTokenClaims.acr)) {
        console.log('graphAPIUser', tenantId, user.tenantId);
        if ((tenantId && tenantId === user.tenantId) || !tenantId) {
          graphAPIUser = user;
        }
      }
    });
    if (!graphAPIUser) {
      await azureMsal.acquireTokenPopup({ scopes, authority }).then((res) => {
        userStore.azureAccessToken = res.accessToken;
      }).catch((err) => {
        this.handleError(err);
      });
      return;
    }

    const acquireTokenSilentRes = await azureMsal.acquireTokenSilent({ scopes, authority, account: graphAPIUser }).then((res) => {
      userStore.azureAccessToken = res.accessToken;
    }).catch(async (err) => {
      if (err instanceof Msal.InteractionRequiredAuthError) {
        await azureMsal.acquireTokenPopup({ scopes, authority }).then((res) => {
          userStore.azureAccessToken = res.accessToken;
        });
        return;
      } else {
        this.handleError(err);
      }
    });
  }

  public acquireAccessTokenPopup = async (scopes: string[], tenantId?: string) => {
    const authority = tenantId ? `https://login.microsoftonline.com/${tenantId}` : 'https://login.microsoftonline.com/common';
    const configuration: Msal.Configuration = {
      auth: {
        clientId: CLIENT_ID,
        authority,
        redirectUri: `${window.location.origin}/azure-callback`,
      },
      system: {
        allowNativeBroker: false
      }
    };

    const azureMsal = await Msal.PublicClientApplication.createPublicClientApplication(configuration);
    try {
      const res = await azureMsal.acquireTokenPopup({ scopes, authority });
      console.log('azure callback acquireTokenPopup', res);
      userStore.azureAccessToken = res.accessToken;
    } catch (err) {
      if (err instanceof Error) {
        this.handleError(err);
      }
    }
  }

  public getTenantIdFromAzureAccessToken = () => {
    let decoded: { tid: string };
    if (userStore.azureAccessToken) {
      try {
        decoded = jwtDecode(userStore.azureAccessToken);
        return decoded.tid;
      } catch (err) {
        return '';
      }
    }
    return '';
  }

  private getScpFromAccessToken = (accessToken: string | null): string => {
    if (!accessToken) {
      return '';
    }
    const decoded = jwtDecode(accessToken) as { scp: string };
    return decoded.scp;
  }

  public isPersonalAccount = (): boolean | undefined => {
    if (!userStore.azureAccessToken) {
      return undefined;
    }
    try {
      const decoded = jwtDecode(userStore.azureAccessToken) as { iss: string };
      return decoded.iss.includes('9188040d-6c67-4c5b-b112-36a304b66dad');
    } catch (e) {
      return true;
    }
  }

  private isScopeInAccessToken = (tokenScopes: string, targetScopes: string[]): boolean => {
    let scpArr = tokenScopes.split(' ');
    scpArr = scpArr.map((scp) => {
      return scp.toLowerCase();
    });
    let result = true;
    targetScopes.forEach((scp) => {
      if (!(scpArr.includes(scp.toLowerCase()))) {
        result = false;
      }
    });
    return result;
  }

  // if the scp is not in target scopes or access token expired or does not have access token
  // we should reacquire access token
  // if return value is true means it needs to reacquire access token
  public validateAzureAccessToken = (scopes: string[], tenantId?: string): boolean => {
    let decoded: { exp: number; iss: string; scp: string; tid: string };
    const accessToken = userStore.azureAccessToken;
    console.log('validate azure access token', accessToken);
    if (!accessToken) {
      return false;
    }
    try {
      decoded = jwtDecode(accessToken);
    } catch (err) {
      return false;
    }
    if (decoded.exp <= new Date().getTime() / 1000) {
      return false;
    }
    if (!this.isScopeInAccessToken(decoded.scp, scopes)) {
      console.log('scope not include');
      return false;
    }
    if (tenantId && decoded.tid !== tenantId) {
      console.log('tenant invalid');
      return false;
    }
    return true;
  };

  public getConditionalAccessPolicies = async (): Promise<GetConditionalAccessPoliciesRes | undefined> => {
    const GET_CONDITIONAL_ACCESS_API = 'https://graph.microsoft.com/v1.0/identity/conditionalAccess/policies';

    if (!this.validateAzureAccessToken(AZURE_CONDITIONAL_ACCESS_SCOPES)) {
      await this.acquireAccessTokenSilent(AZURE_CONDITIONAL_ACCESS_SCOPES);
    }

    if (userStore.azureAccessToken) {
      const response: Response = await fetch(GET_CONDITIONAL_ACCESS_API, this.getParams('GET'));
      const data = await this.handleData(response);
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    }
    return undefined;
  }

  public createConditionAccessPolices = async (payload: any, clientId: string) => {
    payload.conditions.applications.includeApplications = [clientId];
    const CREATE_CONDITIONAL_ACCESS_API = 'https://graph.microsoft.com/v1.0/identity/conditionalAccess/policies';

    if (!this.validateAzureAccessToken(AZURE_CONDITIONAL_ACCESS_SCOPES)) {
      await this.acquireAccessTokenSilent(AZURE_CONDITIONAL_ACCESS_SCOPES);
    }

    if (userStore.azureAccessToken) {
      const response: Response = await fetch(CREATE_CONDITIONAL_ACCESS_API, this.getParams('POST', payload));
      const data = await this.handleData(response);
      await wait(2000);
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    }
    return undefined;
  }

  public deleteConditionalAccessPolicy = async (policyId: string) => {
    const CREATE_CONDITIONAL_ACCESS_API = `https://graph.microsoft.com/v1.0/identity/conditionalAccess/policies/${policyId}`;

    if (!this.validateAzureAccessToken(AZURE_CONDITIONAL_ACCESS_SCOPES)) {
      await this.acquireAccessTokenSilent(AZURE_CONDITIONAL_ACCESS_SCOPES);
    }

    if (userStore.azureAccessToken) {
      const response: Response = await fetch(CREATE_CONDITIONAL_ACCESS_API, this.getParams('DELETE'));
      await this.handleData(response);
      await wait(300);
    }
    return undefined;
  }

  public findServicePrincipalWithAppId = async (azureClientId: string): Promise<GetServicePrincipalRes | undefined> => {
    const GET_SERVICE_PRINCIPAL_API = `https://graph.microsoft.com/v1.0/servicePrincipals?$search="appId:${azureClientId}"`;
    if (!azureClientId) {
      return undefined;
    }
    if (userStore.azureAccessToken) {
      const headers = { ConsistencyLevel: 'eventual' };
      const response: Response = await fetch(GET_SERVICE_PRINCIPAL_API, this.getParams('GET', undefined, headers));
      const data = await this.handleData(response);
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    }
    return undefined;
  }

  public createServicePrincipal = async (azureAppId: string) => {
    const CREATE_SERVICE_PRINCIPAL_API = 'https://graph.microsoft.com/v1.0/servicePrincipals';

    if (userStore.azureAccessToken) {
      const payload = {
        appId: azureAppId,
        tags: ['WindowsAzureActiveDirectoryIntegratedApp'],
      };
      const response: Response = await fetch(CREATE_SERVICE_PRINCIPAL_API, this.getParams('POST', payload));
      const data = await this.handleData(response);
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    }
    return undefined;
  }

  public createApplicationInAzureAD = async (payload: CreateApplicationInAzureADParams): Promise<CreateApplicationInAzureADRes | undefined> => {
    const CREATE_AAD_APP_API = 'https://graph.microsoft.com/v1.0/applications';

    if (userStore.azureAccessToken) {
      const response: Response = await fetch(CREATE_AAD_APP_API, this.getParams('POST', payload));
      const data = await this.handleData(response);
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    }
    return undefined;
  }

  public discoverIssuerInB2C = async (b2cDomain: string, policyName: string, tenantId: string): Promise<{ issuer: string } | undefined> => {
    let tenantName;
    if (b2cDomain.includes('onmicrosoft.com')) {
      tenantName = b2cDomain.split('.')[0];
    }
  
    const GET_ISSUER_API = tenantName ? 
      `https://${tenantName.trim()}.b2clogin.com/tfp/${tenantId.trim()}/${policyName.trim()}/v2.0/.well-known/openid-configuration` :
      `https://${b2cDomain.trim()}/tfp/${tenantId.trim()}/${policyName.trim()}/v2.0/.well-known/openid-configuration`;

    const params = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(GET_ISSUER_API, params);
    const data = await this.handleData(response);
    return new Promise((resolve, reject) => {
      resolve(data);
    });
  }

  public deleteApplicationInAzure = async (appId: string) => {
    const DELETE_APP_API = `https://graph.microsoft.com/v1.0/applications/${appId}`;
    if (userStore.azureAccessToken) {
      const response: Response = await fetch(DELETE_APP_API, this.getParams('DELETE'));
      const data = await this.handleData(response);
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    }
  }

  public createPasswordInAzure = async (appId: string, secretName: string): Promise<CreatePasswordInAzureRes | undefined> => {
    const CREATE_PASSWORD_API = `https://graph.microsoft.com/v1.0/applications/${appId}/addPassword`;
    const payload = {
      passwordCredential: {
        displayName: secretName,
      },
    };
    if (userStore.azureAccessToken) {
      const params = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + userStore.azureAccessToken,
        },
        method: 'POST',
        body: JSON.stringify(payload),
      };
      const response: Response = await fetch(CREATE_PASSWORD_API, params);
      const data = await this.handleData(response);
      return new Promise((resolve, reject) => {
        resolve(data);
      });
    }
    return undefined;
  }
}

export const graphAPIServices = GraphAPIServices.getInstance();
