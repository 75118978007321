/* eslint-disable react/display-name */
/* eslint-disable new-cap */
import {
  ApartmentOutlined, ApiOutlined, AppstoreOutlined, AuditOutlined, ClusterOutlined, DatabaseOutlined, DeploymentUnitOutlined, GroupOutlined, LockOutlined, ReconciliationOutlined, SafetyOutlined, SettingOutlined, TeamOutlined, UserOutlined
} from '@ant-design/icons';
import React from 'react';
import Loadable from 'react-loadable';
import { MerlinLoading } from '../component/MerlinLoading/MerlinLoading';

export const layoutRouter = [
  {
    path: '/home',
    key: 'home',
    name: 'datawiza',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/Home/Home'),
      loading: () => <MerlinLoading />
    })
  },

  {
    path: '/deployments',
    key: 'deployments',
    exact: true,
    name: 'Deployments',
    icon: <DeploymentUnitOutlined />,
    component: Loadable({
      loader: () => import('../page/Deployments/Deployments'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/deployments/:deploymentId/:tab',
    key: 'deployment-detail',
    hidden: true,
    exact: true,
    component: Loadable({
      loader: () => import('../page/DeploymentDetail/DeploymentDetail'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/deployments/:deploymentId/applications/:appId/:tab/:subTab',
    key: 'deployments-application-detail',
    exact: true,
    hidden: true,
    component: Loadable({
      loader: () => import('../page/ApplicationDetail/ApplicationDetail'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/applications',
    key: 'applications',
    name: 'Applications',
    icon: <AppstoreOutlined />,
    component: Loadable({
      loader: () => import('../page/Applications/Applications'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/applications/:appId/:tab/:subTab',
    key: 'applications-application-detail',
    exact: true,
    hidden: true,
    component: Loadable({
      loader: () => import('../page/ApplicationDetail/ApplicationDetail'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/idps',
    key: 'idps',
    name: 'Identity Providers',
    icon: <ApartmentOutlined />,
    component: Loadable({
      loader: () => import('../page/Idps/Idps'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/mfa-configurations',
    key: 'mfa-configurations',
    name: 'MFA',
    icon: <LockOutlined />,
    component: Loadable({
      loader: () => import('../page/MFA/MFA'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/directory',
    key: 'directory',
    name: 'Directory',
    icon: <DatabaseOutlined />,
    component: Loadable({
      loader: () => import('../page/Directory/Directory'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/authorizers',
    key: 'authorizers',
    name: 'Authorizers',
    icon: <SafetyOutlined />,
    component: Loadable({
      loader: () => import('../page/Authorizer/Authorizer'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/audit-log',
    key: 'audit-log',
    name: 'Audit Log',
    icon: <AuditOutlined />,
    component: Loadable({
      loader: () => import('../page/AuditLog/AuditLog'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/access-proxies',
    key: 'access-proxies',
    name: 'Access Proxies',
    isNew: true,
    icon: <ClusterOutlined />,
    component: Loadable({
      loader: () => import('../page/AccessBrokers/AccessBrokers'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/api-tokens',
    key: 'api-tokens',
    name: 'Admin Tokens',
    isBeta: true,
    icon: <ApiOutlined />,
    component: Loadable({
      loader: () => import('../page/ApiTokens/ApiTokens'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/deployments/:deploymentId/applications/:appId/rules/access-rules/create',
    key: 'create-access-rule',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/RuleForm/AccessRuleForm'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/deployments/:deploymentId/applications/:appId/rules/redirect-rules/create',
    key: 'create-redirect-rule',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/RuleForm/RedirectRuleForm'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/directory/:ldapId/:tab',
    key: 'ldap-mappings',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/Directory/AddDirectory'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/directory/configuration/:tab/:action',
    key: 'create-ldap',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/Directory/AddDirectory'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/directory/configuration/:ldapId/:tab/:action',
    key: 'edit-ldap',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/Directory/AddDirectory'),
      loading: () => <MerlinLoading />
    })
  },
  
  {
    path: '/deployments/:deploymentId/applications/:appId/rules/access-rules/:ruleId',
    key: 'edit-access-rule',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/RuleForm/AccessRuleForm'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/deployments/:deploymentId/applications/:appId/rules/redirect-rules/:ruleId',
    key: 'edit-redirect-rule',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/RuleForm/RedirectRuleForm'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/applications/:appId/rules/access-rules/create',
    key: 'applications-create-access-rule',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/RuleForm/AccessRuleForm'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/applications/:appId/rules/redirect-rules/create',
    key: 'applications-create-redirect-rule',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/RuleForm/RedirectRuleForm'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/applications/:appId/rules/access-rules/:ruleId',
    key: 'applications-edit-access-rule',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/RuleForm/AccessRuleForm'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/applications/:appId/rules/redirect-rules/:ruleId',
    key: 'applications-edit-redirect-rule',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/RuleForm/RedirectRuleForm'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/mfa-users',
    key: 'mfa-users',
    name: 'Users',
    icon: <UserOutlined />,
    component: Loadable({
      loader: () => import('../page/MfaUsers/MfaUsers'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/otp-groups',
    key: 'otp-groups',
    name: 'Groups',
    icon: <GroupOutlined />,
    component: Loadable({
      loader: () => import('../page/MfaGroups/MfaGroups'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/otp-groups/:groupId/edit',
    key: 'otp-groups-edit',
    hidden: true,
    icon: <GroupOutlined />,
    component: Loadable({
      loader: () => import('../page/MfaGroups/GroupDetail'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/account/:type',
    key: 'account',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/Account/Account'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/team',
    key: 'team',
    name: 'Team',
    icon: <TeamOutlined />,
    component: Loadable({
      loader: () => import('../page/Team/Team'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/plan',
    key: 'plan',
    name: 'Plan',
    icon: <ReconciliationOutlined />,
    component: Loadable({
      loader: () => import('../page/Plan/Plan'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/setting',
    key: 'setting',
    name: 'Settings',
    icon: <SettingOutlined />,
    component: Loadable({
      loader: () => import('../page/Settings/Settings'),
      loading: () => <MerlinLoading />
    })
  },
  
  {
    path: '/404',
    key: 'not-found',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/NotFound/NotFound'),
      loading: () => <MerlinLoading />
    })
  },
  {
    path: '/403',
    key: 'no-access',
    hidden: true,
    component: Loadable({
      loader: () => import('../page/NotFound/NotFound'),
      loading: () => <MerlinLoading />
    })
  }
];

export const superAdminMenu = [
  {
    path: '/users',
    key: 'users',
    name: 'Users',
    icon: <UserOutlined />,
    component: Loadable({
      loader: () => import('../page/Users/Users'),
      loading: () => <MerlinLoading />
    })
  }
];
