import { message } from 'antd';
import { action, observable } from 'mobx';
import { SSL, UpdateSSLParams } from '../services/model/ssl';
import Services from '../services/services';

const DEFAULT_SSL_SETTING: Partial<SSL> = {
  isSSL: false,
};
export class SSLStore {
  private static instance: SSLStore;

  public static getInstance(): SSLStore {
    if (!SSLStore.instance) {
      SSLStore.instance = new SSLStore();
    }
    return SSLStore.instance;
  }

  @observable currentSSL: Partial<SSL> = {};
  @observable loading = false;

  init(appId: string) {
    this.getSSL(appId);
  }

  @action
  async getSSL(appId: string) {
    try {
      const res = await Services.getSSL({ appId });
      this.currentSSL = { ...DEFAULT_SSL_SETTING, ...res.data };
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  }

  @action
  updateSSL(data: UpdateSSLParams, appId: string) {
    return Services.updateSSL({ ...data, appId });
  }
}

export const sslStore = SSLStore.getInstance();
