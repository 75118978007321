import { message, Modal } from 'antd';
import { action, observable, runInAction } from 'mobx';
import { adminTokenMessage } from '../i18n/i18n';
import { APIToken, APITokensRes, CreateApiTokenParams } from '../services/model/apiToken';
import Services from '../services/services';
const { confirm } = Modal;
const tokenModalDefaultValue = {
  visible: false,
  okText: 'Create Admin Token',
  apiTokenDisplay: false,
};
export class ApiTokensStore {
  private static instance: ApiTokensStore;

  public static getInstance() {
    if (!ApiTokensStore.instance) {
      ApiTokensStore.instance = new ApiTokensStore();
    }

    return ApiTokensStore.instance;
  }

  @observable tokenModal: {visible: boolean; okText: string; apiTokenDisplay: boolean} = Object.assign(tokenModalDefaultValue);
  @observable apiTokensData: Partial<APITokensRes> = {};

  @observable createdApiToken: Partial<APIToken> = {};

  init() {
    this.getData(0);
  }

  convertTime() {
    return this.apiTokensData.data?.forEach((token) => {
      token.creationDate = new Date(token.creationDate).toLocaleString();
      token.expire = new Date(token.expire).toLocaleString();
    });
  }

  @action
  initModalState = () => {
    this.tokenModal = Object.assign(tokenModalDefaultValue);
  }

  @action
  showModal = () => {
    this.initModalState();
    this.tokenModal.visible = true;
  };

  @action
  handleOk = (payload: CreateApiTokenParams) => {
    if (!this.tokenModal.apiTokenDisplay) {
      console.log('create admin token payload', payload);
      payload.name = payload.name.trim();
      this.createData(payload);
    } else {
      this.tokenModal.visible = false;
      this.init();
    }
  };

  @action
  handleCancel = () => {
    this.tokenModal.visible = false;
    if (this.tokenModal.apiTokenDisplay) {
      this.init();
    }
  };

  @action
  getData = (page: number) => {
    Services.getAPITokens({
      page,
      size: 5,
    }).then((res) => {
      console.log(res);
      runInAction(() => {
        this.apiTokensData = res;
        this.convertTime();
      });
    }).catch((e) => {
      message.error(e.message);
    });
  }

  @action
  createData = (payload: CreateApiTokenParams) => {
    Services.createAPIToken(payload).then((res) => {
      runInAction(() => {
        this.createdApiToken = res.data;
        this.tokenModal.apiTokenDisplay = true;
        this.tokenModal.okText = 'OK, got it';
      });
    }).catch((e) => {
      message.error(e.message);
    });
  }

  @action
  deleteData = (apiTokenId: string) => {
    confirm({
      title: 'Do you want to delete this token?',
      okButtonProps: { danger: true },
      okText: 'Delete',
      onOk: () => {
        Services.revokeAPIToken({ apiTokenId: apiTokenId }).then((res) => {
          console.log(res);
          if (res.errCode === 0) {
            this.apiTokensData.data = this.apiTokensData.data?.filter((apiKey) => {
              return apiKey.id !== apiTokenId;
            });
            message.success(adminTokenMessage.deleteSuccess);
            this.init();
          } else {
            message.error(res.errMsg);
          }
        }).catch((e) => {
          message.error(e.message);
        });
      },
    });
  }

  @action
  cancelCreation = () => {
    console.log('Canceled creation!');
  }
}

export const apiTokensStore = ApiTokensStore.getInstance();
