import { action, observable } from 'mobx';
import { format } from 'timeago.js';
import { AbComponentEnum, ActivityStatusEnum, ActivityTypeEnum } from '../common/constants';
import Services from '../services/services';
import { Activity } from '../types/app';


export class MessageStore {
  private static instance: MessageStore;

  public static getInstance(): MessageStore {
    if (!MessageStore.instance) {
      MessageStore.instance = new MessageStore();
    }
    return MessageStore.instance;
  }

  @observable messages: Array<Activity> = [];
  @observable dot = false;

  @action
  async getMessages(page: number, size: number) {
    const res = await Services.getActivities({
      page: page,
      size: size,
      type: [ActivityTypeEnum.CONFIG],
      component: AbComponentEnum.RP
    });

    return { messages: this.massageMessage(res.data), totalElements: res.totalElements };
  }

  massageMessage(data: Array<Activity>): Array<Activity> {
    let tmp = data;

    tmp = tmp.filter((item: Activity) => {
      return item.component.includes('RP');
    });
    // tmp.splice(4, tmp.length);
    tmp.map((item) => {
      item.timeAgo = format(item.creationDate);
      if (item.deploymentName === undefined) {
        item.deploymentName = `Deployment ${item.deploymentId}`;
      };
      if (item.status === ActivityStatusEnum.OK) {
        item.message = `${item.deploymentName} applied new configuration to Access Proxy (${item.abId}) successfully`;
      } else if (item.status === ActivityStatusEnum.WARNING) {
        item.message = `${item.deploymentName} applied new configuration to Access Proxy (${item.abId}) failed`;
      }
    });
    return tmp;
  }

  @action
  fetchMessage = async (page: number, size: number) => {
    const { messages, totalElements } = await this.getMessages(page, size);
    if (messages.length === 0) {
      return { messages, totalElements };
    }
    const lastReadMessageTime = localStorage.getItem('LAST_READ_MESSAGE_TIME');
    if (messages[0] && (lastReadMessageTime == null || lastReadMessageTime !== messages[0].creationDate.toString())) {
      this.dot = true;
    }
    localStorage.setItem('LAST_READ_MESSAGE_TIME', messages[0].creationDate.toString());
    return { messages, totalElements };
  };

  @action
  readMessage = (messages: Activity[]) => {
    if (this.dot && messages.length > 0) {
      localStorage.setItem('LAST_READ_MESSAGE_TIME', messages[0].creationDate.toString());
      this.dot = false;
    }
  }
}

export const messageStore = MessageStore.getInstance();
